import React, { useState, useContext, useEffect } from "react";
import { StyleSheet, View, Image, Text } from "react-native";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useLocation,
} from "react-router-dom";
import { Table, Row, Rows } from "react-native-table-component-2";
import AppContext from "./AppContext";
import required from "../assets/required.png";
import possiblyRequired from "../assets/possibly-required.png";
import notRequired from "../assets/not-required.png";
import SliderOption from "./ProgressBar";

const CompanyTable = () => {
  const context = useContext(AppContext);
  const setData = () => {
    context.setTableHead(["SOURCE", "REQUIREMENT", "SCORECARD"]);
    context.setTableData([
      context.dataTable,
      context.dataTable2,
      context.dataTable3,
    ]);
    // console.log(context.modalText);
    // console.log(context.dataTable);
    // console.log(context.fROB);
    tableData();
  };
  const tableData = () => {
    // console.log(context.modalText);
    switch (context.modalText) {
      case "Empowering Women":
        return (
          tableImage(context.cardData),
          wordingForRequirements(context.cardData),
          context.setSource1(context.sources[0]),
          isScoreNoDataEW(context.fROB),
          console.log("EM", context.dataTable[2])
        );
      // break;
      case "Prison Profiteering":
        return (
          tableImage(context.dataTable[2]),
          wordingForRequirements(context.dataTable[2]),
          tableImage2(context.dataTable2[2]),
          wordingForRequirements2(context.dataTable2[2]),
          tableImage3(context.invPrisLaborScore),
          wordingForRequirements3(context.invPrisLaborScore),
          isScoreNoData(context.prisonHarmScore),
          // meetsRequirements(context.immDetLabor),
          // meetsRequirements2(context.invPrisLabor),
          context.setSource1(context.sources[7]),
          context.setSource2(context.sources[7]),
          context.setSource3(context.sources[7]),
          console.log(context.prisonData)
        );
      case "Dark Money":
        return (
          console.log(context.darkMoneyCPAScore),
          tableImage(context.dataTable[2]),
          wordingForRequirements(context.dataTable[2]),
          context.setSource1(context.sources[5]),
          isScoreNoData(Math.round(context.darkMoneyCPAScore))
          // console.log(
          //   "DM",
          //   context.dataTable[2],
          //   context.dataTable2[2],
          //   context.dataTable3[2]
          // )
        );
      case "Climate Change":
        return (
          tableImage(context.dataTable[2]),
          wordingForRequirements(context.dataTable[2]),
          tableImage2(context.dataTable2[2]),
          wordingForRequirements2(context.dataTable2[2]),
          tableImage3(context.dataTable3[2]),
          wordingForRequirements3(context.dataTable3[2]),
          context.setSource1(context.sources[8]),
          context.setSource2(context.sources[9]),
          context.setSource3(context.sources[3])
          // isCheckmark(context.presenceClean200Score),
          // meetsRequirements(context.gHPresence)
          // console.log(
          //   "CC",
          //   context.dataTable[2],
          //   context.dataTable2[2],
          //   context.dataTable3[2]
          // )
        );
      case "Racial Justice":
        return (
          tableImage(context.dataTable[2]),
          wordingForRequirements(context.dataTable[2]),
          tableImage2(context.dataTable2[2]),
          wordingForRequirements2(context.dataTable2[2]),
          context.setSource1(context.sources[2]),
          context.setSource2(context.sources[3]),
          isScoreNoDataEW(Math.round(context.airPollutantScore * 100) + "%"),
          isScoreNoData2(Math.round(context.greenHousePercentage * 100) + "%")
          // console.log(
          //   "RJ",
          //   context.dataTable[2],
          //   context.dataTable2[2],
          //   context.dataTable3[2]
          // )
        );
      case "People Over Profits":
        return (
          tableImage(context.ceoPayRatio.map(Number)),
          wordingForRequirements(context.ceoPayRatio.map(Number)),
          tableImage2(context.sHScore.map(Number)),
          wordingForRequirements2(context.sHScore.map(Number)),
          context.setSource1(context.sources[10]),
          context.setSource2(context.sources[6]),
          isScoreNoDataEW(context.payRatioScore),
          console.log(context.sHScore, context.ceoPayRatio)
        );
      default:
        return console.log("default");
    }
  };

  const tableImage = (data) => {
    console.log(data);
    if (data[0] === 0) {
      context.setTableScoreImage(possiblyRequired);
      // console.log("default");
    } else if (data[0] === 1) {
      context.setTableScoreImage(required);
      // console.log("worked");
    } else {
      context.setTableScoreImage(notRequired);
      // console.log("got it");
    }
  };
  const tableImage2 = (data) => {
    console.log(data);
    if (data[0] === 0) {
      context.setTableScoreImage2(possiblyRequired);
      // console.log("default");
    } else if (data[0] === 1) {
      context.setTableScoreImage2(required);
      // console.log("worked");
    } else {
      context.setTableScoreImage2(notRequired);
      // console.log("got it");
    }
  };
  const tableImage3 = (data) => {
    // console.log(data);
    if (data[0] === 0) {
      context.setTableScoreImage3(possiblyRequired);
      // console.log("default");
    } else if (data[0] === 1) {
      context.setTableScoreImage3(required);
      // console.log("worked");
    } else {
      context.setTableScoreImage3(notRequired);
      // console.log("got it");
    }
  };
  const isScoreNoDataEW = (data) => {
    console.log(data);
    if (data == "No Data" || data == "NaN%") {
      context.setScoreNumber("");
    } else {
      context.setScoreNumber(data);
    }
  };
  const isScoreNoData = (data) => {
    console.log(data);
    if (data == "No Data" || data == "NaN%" || isNaN(data)) {
      context.setScoreNumber("");
    } else {
      context.setScoreNumber(data);
    }
  };
  const isScoreNoData2 = (data) => {
    console.log(data);
    if (data == "No Data" || data == "NaN%") {
      context.setScoreNumber2("");
    } else {
      context.setScoreNumber2(data);
    }
  };
  const isScoreNoData3 = (data) => {
    if (data[0] === "No Data") {
      context.setScoreNumber3("");
    } else {
      context.setScoreNumber3(data[0]);
    }
  };
  const meetsRequirements = (data) => {
    if (data == "No") {
      context.setScoreNumber2("Meets Requirements");
    } else if (data == "Yes") {
      context.setScoreNumber2("Doesn't Meet Requirements");
    } else {
      context.setScoreNumber2("");
    }
  };
  const meetsRequirements2 = (data) => {
    // console.log(data);
    if (data == "No") {
      context.setScoreNumber3("Meets Requirements");
    } else if (data == "Yes") {
      context.setScoreNumber3("Doesn't Meet Requirements");
    } else {
      context.setScoreNumber3("");
    }
  };
  const isCheckmark = (data) => {
    if (data == "Yes") {
      context.setScoreNumber("Meets Requirements");
    } else if (data == "No") {
      context.setScoreNumber("Doesn't Meet Requirements");
    } else {
      context.setScoreNumber("");
    }
  };
  const wordingForRequirements = (data) => {
    console.log("data", data);
    // console.log(data);
    // if (data == "No data" || data == "NaN") {
    //   context.setMeetsRequirementsWording("N/A");
    // } else {
    if (data[0] === 0) {
      context.setMeetsRequirementsWording("Doesn't Meet Requirements");
      // console.log("default");
    } else if (data[0] === 1) {
      context.setMeetsRequirementsWording("Meets Requirements");
      // console.log("worked");
    } else {
      context.setMeetsRequirementsWording("Doesn't Meet Requirements");
      // console.log("got it");
    }
    // }
  };
  const wordingForRequirements2 = (data) => {
    console.log("data", data);
    // console.log(data);
    if (data[0] === 0) {
      context.setMeetsRequirementsWording2("Doesn't Meet Requirements");
      // console.log("default");
    } else if (data[0] === 1) {
      context.setMeetsRequirementsWording2("Meets Requirements");
      // console.log("worked");
    } else {
      context.setMeetsRequirementsWording2("Doesn't Meet Requirements");
      // console.log("got it");
    }
  };
  const wordingForRequirements3 = (data) => {
    console.log(data);
    if (data[0] === 0) {
      context.setMeetsRequirementsWording3("Doesn't Meet Requirements");
      // console.log("default");
    } else if (data[0] === 1) {
      context.setMeetsRequirementsWording3("Meets Requirements");
      // console.log("worked");
    } else {
      context.setMeetsRequirementsWording3("Doesn't Meet Requirements");
      // console.log("got it");
    }
  };

  useEffect(() => {
    setData();
    // console.log(context.modalText)
  }, [context.modalText]);

  return (
    <View style={styles.container}>
      <View style={styles.styleBorder}>
        {context.tableHead.map((item, index) => {
          return (
            <View style={styles.styleRow} key={index}>
              <Text style={styles.text}>{item}</Text>
            </View>
          );
        })}
      </View>
      {/* <Table borderStyle={styles.styleBorder}>
        <Row
          data={context.tableHead}
          style={styles.head}
          textStyle={styles.text}
        />
        <Rows data={context.tableData} textStyle={styles.text} />
        {/* <Image
      source={context.modalScoreImage}
      style={{
        maxWidth: 20,
        minWidth: 20,
        minHeight: 40,
        maxHeight: 40,
        height: 40,
        width: 40,
        alignSelf: "flex-end",
        position: "absolute",
      }}
    ></Image> */}
      {/* </Table> */}
      {context?.dataTable?.length > 0 && (
        <View style={styles.dataRowStyle}>
          <Link
            to={{
              pathname: context.source1,
            }}
            target="_blank"
            style={{
              textDecoration: "none",
              placeSelf: "center",
              textAlign: "center",
            }}
          >
            <Text style={styles.dataText}>{context.dataTable[0]}</Text>
          </Link>
          <Text style={styles.dataText}>{context.dataTable[1]}</Text>
          <View>
            <Image
              source={context.tableScoreImage}
              style={styles.coloredCorners}
            />
            {/* <SliderOption
              data1={context.dataTable}
              title={context.modalText}
              datas={context.scoreNumber}
            /> */}
            <Text style={styles.dataText}>
              {context.meetsRequirementsWording}
            </Text>
            <Text style={styles.dataText}>{context.scoreNumber}</Text>
          </View>
        </View>
      )}
      {context.dataTable2.length > 0 && (
        <View style={styles.dataRowStyle}>
          <Link
            to={{
              pathname: context.source2,
            }}
            target="_blank"
            style={{
              textDecoration: "none",
              placeSelf: "center",
              textAlign: "center",
            }}
          >
            <Text style={styles.dataText}>{context.dataTable2[0]}</Text>
          </Link>
          <Text style={styles.dataText}>{context.dataTable2[1]}</Text>
          <View>
            <Image
              source={context.tableScoreImage2}
              style={styles.coloredCorners}
            />
            {/* <SliderOption
              data1={context.dataTable2}
              datas={context.scoreNumber2}
            /> */}
            <Text style={styles.dataText}>
              {context.meetsRequirementsWording2}
            </Text>
            <Text style={styles.dataText}>{context.scoreNumber2}</Text>
          </View>
          {/* {{
              1: <Image source={required} style={styles.coloredCorners} />,
              0: <Image source={possiblyRequired} style={styles.coloredCorners} />,
            }[context.cardData] || (
              <Image source={notRequired} style={styles.coloredCorners} />
            )} */}
        </View>
      )}
      {context.dataTable3.length > 0 && (
        <View style={styles.dataRowStyle}>
          <Link
            to={{
              pathname: context.source3,
            }}
            target="_blank"
            style={{
              textDecoration: "none",
              placeSelf: "center",
              textAlign: "center",
            }}
          >
            <Text style={styles.dataText}>{context.dataTable3[0]}</Text>
          </Link>
          <Text style={styles.dataText}>{context.dataTable3[1]}</Text>
          <View>
            <Image
              source={context.tableScoreImage3}
              style={styles.coloredCorners}
            />
            {/* <SliderOption
              data1={context.dataTable3}
              datas={context.scoreNumber3}
            /> */}
            <Text style={styles.dataText}>
              {context.meetsRequirementsWording3}
            </Text>
            <Text style={styles.dataText}>{context.scoreNumber3}</Text>
          </View>
          {/* {{
              1: <Image source={required} style={styles.coloredCorners} />,
              0: <Image source={possiblyRequired} style={styles.coloredCorners} />,
            }[context.cardData] || (
              <Image source={notRequired} style={styles.coloredCorners} />
            )} */}
        </View>
      )}
      {/* {context.tableData.map((item, index) => {
        return (
          <View style={styles.dataRowStyle} key={index}>
            <Text style={styles.dataText}>{item}</Text>
          </View>
        );
      })} */}
    </View>
  );
};
export default CompanyTable;

const styles = StyleSheet.create({
  styleBorder: {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(125px, 31%))",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    gridAutoFlow: "column",
  },
  styleRow: {
    placeSelf: "center",
  },
  coloredCorners: {
    //width: 40,
    //height: 40,
    height: "clamp(10px, 3vw, 21px)",
    width: "clamp(10px, 3vw, 21px)",
    placeSelf: "center",
  },
  dataRowStyle: {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(125px, 31%))",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 15,
    paddingBottom: 15,
  },
  container: { flex: 1, paddingTop: 30, width: "clamp(360px, 100%, 100%)" },
  head: { height: 40, fontFamily: "Typekit" },
  text: {
    margin: 6,
    fontFamily: "Typekit",
    color: "white",
    textAlign: "center",
    fontSize: "clamp(10px, 2vw, 0.8em)",
  },
  dataText: {
    margin: 6,
    fontFamily: "Typekit",
    color: "white",
    textAlign: "center",
    padding: 0,
    placeSelf: "center",
    fontSize: "clamp(10px, 2vw, 0.8em)",
  },
  modalImgContainer: {
    alignSelf: "flex-start",
    top: "-7vw",
    left: 0,
    width: "25%",
    minWidth: 200,
  },
});
