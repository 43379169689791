import React, { useContext, useState, useEffect } from "react";
import Helmet from 'react-helmet';
import { View, Text, Modal, StyleSheet, Pressable, Image } from "react-native";
import { BrowserRouter as Router, Link } from "react-router-dom";
import AppContext from "./AppContext";
import axios from "axios";
import Logo from "./Logo";
import POPLogo from "../assets/people-over-profits-10.19.png";
import Footer from "./Footer";
import BackHomeButton from "./BackHomeButton";
import POPInfo from "../assets/pop-infographic.png";
import Infographic from "./Infographics";
import Sharingoption from './SharignOptions'
// import Home from './Home'

const PeopleOverProfits = () => {
  const context = useContext(AppContext);
    const [seoData, setSeo] = useState([]);
    
    const getSeoData = async () => {
      try {
        const seo = await axios.get(
          `${ process.env.APP_MANIFEST.Local_URL}seo?pageName=HumanCapital`
        );
        setSeo(
          seo?.data[0]
        );
      } catch (error) {
        // handle error
        alert(error);
      }
    }
    
    useEffect(() => {
      if (seoData.length === 0) {
          console.log("Getting SEO Data");
        getSeoData();
      }
    });
    
    return (
      <>
      <Helmet>
        <meta charset="utf-8" />
        <meta httpequiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1.00001, viewport-fit=cover" />
            <meta name="description" content={seoData.metaDesc} />
            <meta name="keywords" content={seoData.metaKeywords} />
        <title>{seoData.pageTitle}</title>
      </Helmet><View style={styles.container}>
      <Logo
        style={{
          resizeMode: "contain",
          width: 250,
          height: 65,
          placeSelf: "center",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "2em",
        }}
      />
      <View style={styles.innerContainer}>
        <BackHomeButton />
        <View style={styles.innerPageContainer}>
          <Sharingoption />
          <Image
            source={POPLogo}
            style={{
              width: "clamp(320px, 400px, 24vw)",
              height: "clamp(320px, 400px, 23vw)",
            }}
          />
          <View style={styles.paragraph}>
            <Text
              style={{
                textAlign: "left",
                color: "#FFFFFF",
                fontSize: "clamp(45px, 3.2vw, 62px)",
                fontFamily: "Gilroy-Bold",
              }}
            >
              People Over Profits
            </Text>
            <Text
              style={{
                textAlign: "left",
                color: "#FFFFFF",
                fontSize: 24,
                lineHeight: "1.7em",
                marginTop: "1.7em",
                fontFamily: "Typekit",
                paddingBottom: "1.7em",
                borderBottom: "1px solid white",
              }}
            >
              {`For years, there has been an emphasis on placing profits over people, where the beliefs of corporations and businesses, place the importance of profits above all else. As awareness grows and people voice their demand for change, more companies are evolving to “conscious capitalism”.  The term conscious capitalism refers to a socially responsible economic and political philosophy. The premise behind conscious capitalism is that businesses should operate ethically while they pursue profits. `}
            </Text>
            <Infographic
              style={{
                resizeMode: "contain",
                maxWidth: 800,
                width: "100%",
                height: 600,
                placeSelf: "center",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "2em",
              }}
              source={POPInfo}
            />
            <Text
              style={{
                textAlign: "left",
                color: "#FFFFFF",
                fontSize: 24,
                lineHeight: "1.7em",
                marginTop: "1.7em",
                fontFamily: "Typekit",
              }}
            >
              {`CEOs on average earned 18.9% more in 2020 than 2019.

While CEOs earn more, food banks across the US are serving 55% more people now than before the pandemic.

In the many U.S. states and cities that do not mandate paid sick leave, 62% of workers lack access to paid sick leave.

Half of workers surveyed who experienced a qualifying event - a new child, a personal health need, or a caregiving need - did not take any leave from their job. Among workers who either did not take leave or did not take as much leave as they needed, nearly three-quarters of workers (71%) indicated the reason was that they could not afford to.

Corporations and business are primarily responsible for the vast majority of greenhouse emissions. 100 energy companies are responsible for 71% of all industrial emissions.

Progress is starting to be made, with top CEOs signing a statement prioritizing People Over Profit in August of 2019.  Mark Hunter, President and CEO of Molson Coors, says, “Our consumers and our customers are looking for assurances that we are doing business the right way. It’s becoming table stakes. Further making the point of the importance of People Over Profits, Alex Ricard, CEO of Pernod Ricard, states, “I need to recognize where consumers want us in ten years…I believe businesses that are only targeting profits will die.`}
            </Text>
          </View>
        </View>
      </View>
      <Footer />
    </View></>
  );
};
const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  container: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    // backgroundImage: `url(${InteriorBG})`
  },
  innerContainer: {
    maxWidth: 1200,
    width: "100%",
    margin: "0 auto",
  },
  innerPageContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row-reverse",
    // justifyContent: "space-around",
    justifyContent:'space-between',
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  paragraph: {
    width: "clamp(65%, 100%, 780px)",
  },
});
export default PeopleOverProfits;
