import React, { useContext, useState, useEffect } from "react";
import Helmet from 'react-helmet';
import { View, Text, Modal, StyleSheet, Pressable, Image } from "react-native";
import { BrowserRouter as Router, Link } from "react-router-dom";
import AppContext from "./AppContext";
import axios from "axios";
import Logo from "./Logo";
import CCLogo from "../assets/climate-change.png";
import Footer from "./Footer";
import BackHomeButton from "./BackHomeButton";
import CCInfo from "../assets/climate-change-infographic.png";
import Infographic from "./Infographics";
import  Sharingoption from './SharignOptions'
// import Home from './Home'

const ClimateChange = () => {
  const context = useContext(AppContext);
    const [seoData, setSeo] = useState([]);
    
    const getSeoData = async () => {
      try {
        const seo = await axios.get(
          `${ process.env.APP_MANIFEST.Local_URL}seo?pageName=Climate`
        );
        setSeo(
          seo?.data[0]
        );
      } catch (error) {
        // handle error
        alert(error);
      }
    }
    
    useEffect(() => {
      if (seoData.length === 0) {
          console.log("Getting SEO Data");
        getSeoData();
      }
    });
    
    return (
      <>
      <Helmet>
        <meta charset="utf-8" />
        <meta httpequiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1.00001, viewport-fit=cover" />
            <meta name="description" content={seoData.metaDesc} />
            <meta name="keywords" content={seoData.metaKeywords} />
        <title>{seoData.pageTitle}</title>
      </Helmet><View style={styles.container}>
      <Logo
        style={{
          resizeMode: "contain",
          width: 250,
          height: 65,
          placeSelf: "center",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "2em",
        }}
      />
      <View style={styles.innerContainer}>
        <BackHomeButton />
        <View style={styles.innerPageContainer}>
        <Sharingoption />
          <Image
            source={CCLogo}
            style={{
              width: "clamp(320px, 400px, 24vw)",
              height: "clamp(320px, 400px, 23vw)",
            }}
          />
          <View style={styles.paragraph}>
         
            <Text
              style={{
                textAlign: "left",
                color: "#FFFFFF",
                fontSize: "clamp(45px, 3.2vw, 62px)",
                fontFamily: "Gilroy-Bold",
              }}
            >
              Climate Change
            </Text>
            <Text
              style={{
                textAlign: "left",
                color: "#FFFFFF",
                fontSize: 24,
                lineHeight: "1.7em",
                marginTop: "1.7em",
                fontFamily: "Typekit",
                paddingBottom: "1.7em",
                borderBottom: "1px solid white",
              }}
            >
              {`“If you really think that the environment is less important than the economy, try holding your breath while you count your money.” — Guy McPherson`}
            </Text>
            <Infographic
              style={{
                resizeMode: "contain",
                maxWidth: 800,
                width: "100%",
                height: 600,
                placeSelf: "center",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "2em",
              }}
              source={CCInfo}
            />
            <Text
              style={{
                textAlign: "left",
                color: "#FFFFFF",
                fontSize: 24,
                lineHeight: "1.7em",
                marginTop: "1.7em",
                fontFamily: "Typekit",
              }}
            >
              {`The concentration of carbon dioxide in our atmosphere (416 parts per million), as of July 2021, is the highest it has been in human history.

Greenland lost an average of 279 billion tons of ice per year between 1993 and 2019, while Antarctica lost about 148 billion tons.
`}
            </Text>
            <Text
              style={{
                textAlign: "left",
                color: "#FFFFFF",
                fontSize: 24,
                lineHeight: "1.7em",
                marginTop: "1.7em",
                fontFamily: "Typekit",
              }}
            >
              {`We only have one planet Earth. We all need to protect it now as you can’t go back once the damage is done.  So true is the Native American Proverb, “We do not inherit the earth from our ancestors.  We borrow it from our children.”`}
            </Text>
          </View>
        </View>
      </View>
      <Footer />
    </View></>
  );
};
const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  container: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    // backgroundImage: `url(${InteriorBG})`
  },
  innerContainer: {
    maxWidth: 1200,
    width: "100%",
    margin: "0 auto",
  },
  innerPageContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row-reverse",
    // justifyContent: "space-around",
    justifyContent:'space-between',
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  paragraph: {
    width: "clamp(65%, 100%, 780px)",
  },
});
export default ClimateChange;
