import React, { useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  Text,
  View,
  FlatList,
  Image,
  TextInput,
  TouchableOpacity,
  Button,
  Pressable,
  Modal,
  TouchableWithoutFeedback,
} from "react-native";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useLocation,
} from "react-router-dom";

import Oval from "../assets/oval.png";
import Logo from "./Logo";
import AboutCompany from "./AboutCompany";
import { CardViewWithImage, CardView } from "react-native-simple-card-view";
import WELogo from "../assets/women-empowerment.png";
import PPLogo from "../assets/prisoner-profiteering.png";
import DMLogo from "../assets/dark-money-10.19.png";
import CCLogo from "../assets/climate-change.png";
import HCLogo from "../assets/people-over-profits-10.19.png";
import RJLogo from "../assets/racial-justice-09.png";
import Icon from "react-native-vector-icons/FontAwesome";
// import { Tooltip } from "native-base";
import axios from "axios";
import { BackButton } from "react-router-native";
import clickCompany from "./Home";
import AppContext from "./AppContext";
import greenCard from "../assets/green-corner.png";
import yellowCard from "../assets/yellow-corner.png";
import redCard from "../assets/red-corner.png";
import required from "../assets/required.png";
import possiblyRequired from "../assets/possibly-required.png";
import notRequired from "../assets/not-required.png";
import Footer from "./Footer";
import BackHomeButton from "./BackHomeButton";
import CompanyTable from "./CompanyTable";
import {buttonPress , selectedContent , analytics} from './FirebaseConfig/firebaseconfig'


const miniCardStyle = {
  shadowColor: "#000000",
  shadowOffsetWidth: 2,
  shadowOffsetHeight: 2,
  shadowOpacity: 0.1,
  shadowRadius: 5,
  bgColor: "#ffffff",
  padding: 5,
  margin: 5,
  borderRadius: 3,
  elevation: 3,
  // color: '#FFFFFF'
};

const CompanyDetails = () => {
  const context = useContext(AppContext);
  const [companyData, setCompanyData] = useState([]);
  //  const analytics = getAnalytics(app);


  //   location = useLocation();
  const [firstCompany, setFirstCompany] = useState("");
  const getDataUsingAsyncAwaitGetCall = async () => {
    try {
      const response = await axios.get(
        // "https://lmback.mediauradev.com/companydetailsearch"
        // "https://backend.lightmoney.com/companydetailsearch"
        `${process.env.APP_MANIFEST.URL}companydetailsearch`
      );
      setCompanyData(JSON.stringify(response.data));
      //   setFirstCompany(response.data[0])
    } catch (error) {
      // handle error
      alert(error.message);
    }
  };
  useEffect(() => {
    // getDataUsingAsyncAwaitGetCall();
    companyDetails();
    // console.log(context.clickedCompany);
  }, [context.clickedCompany]);
  // useEffect(() => {
  //   context.setModalVisible(!context.modalVisible);
  //   context.setDataTable([]);
  //   context.setDataTable2([]);
  //   context.setDataTable3([]);
  //   context.setModalBGStyle([]);
  //   context.setModalCloseColor("");
  //   context.setModalScoreImage("");
  //   context.setTableScoreImage("");
  //   context.setTableScoreImage2("");
  //   context.setTableScoreImage3("");
  //   context.setSource1("");
  //   context.setSource2("");
  //   context.setSource3("");
  //   context.setScoreNumber("");
  //   context.setScoreNumber2("");
  //   context.setScoreNumber3("");
  // }, [context.setModalVisible]);
  const getAllCompanyNames = async () => {
    try {
      const response = await axios.get(
        // "https://lmback.mediauradev.com/databasecompanynames"
        // "https://backend.lightmoney.com/companydetailsearch"
        `${process.env.APP_MANIFEST.URL}companydetailsearch`
      );
      setCompanyNames(
        response.data.map((res, i) => response.data[i]["Company Name"])
      );
      setfilteredName(
        response.data.map((res, i) => response.data[i]["Company Name"])
      );
    } catch (error) {
      // handle error
      alert(error.message);
    }
  };
  const modalBGColor = (data) => {
    // console.log(data);
    if (data[0] === 0) {
      context.setModalBGStyle(styles.modalViewYellow);
      context.setModalCloseColor(styles.closeButtonYellow);
      // console.log("worked");
    } else if (data[0] === 1) {
      context.setModalBGStyle(styles.modalViewGreen);
      context.setModalCloseColor(styles.closeButtonGreen);
    } else {
      context.setModalBGStyle(styles.modalView);
      context.setModalCloseColor(styles.closeButtonRed);
    }
  };
  const modalOSImage = (data) => {
    // console.log(data);
    if (data[0] === 0) {
      context.setModalScoreImage(possiblyRequired);
    } else if (data[0] === 1) {
      context.setModalScoreImage(required);
    } else {
      context.setModalScoreImage(notRequired);
    }
  };
  const companyDetails = async () => {
      //console.log("In Async Details");
      //console.log(window.location.pathname);
      var str = window.location.pathname;
      str = str.substring(9, str.length);
      str = str.replace(/_/g, " ");
      console.log (decodeURIComponent(str));
      //console.log(context.clickedCompany);
      str = str + '%25';
      console.log(str);
    try {
      const request = await axios({
        method: "post",
        // url: "https://backend.lightmoney.com/companydetailsearch",
        url: `${process.env.APP_MANIFEST.URL}companydetailsearch`,
        // "https://lmback.mediauradev.com/companydetailsearch",
        data: {
            //clickedCompany: context.clickedCompany,
            clickedCompany: decodeURIComponent(str),
        },
      });
      context.setCompanyData(
        request.data.map((res, i) => request.data[i]["Company Name"])
      );
      context.setCardData(
        request.data.map((res, i) => request.data[i]["FR Overall Rating"])
      );
      context.setFROB(
        request.data.map(
          (res, i) => request.data[i]["Female Representation on Board"]
        )
      );
      context.setPrisonData(
        request.data.map((res, i) => request.data[i]["PI Overall Rating2"])
      );
      context.setHarmScore(
        request.data.map((res, i) => request.data[i]["Harm Score Rating"])
      );
      context.setPrisonHarmScore(
        request.data.map(
          (res, i) => request.data[i]["Prison Industrial Complex Harm Score"]
        )
      );
      context.setImmScore(
        request.data.map((res, i) => request.data[i]["Immigration Score"])
      );
      context.setImmDetLabor(
        request.data.map(
          (res, i) => request.data[i]["Immigration Detention Labor"]
        )
      );
      context.setPrisonLaborScore(
        request.data.map((res, i) => request.data[i]["Pl Rating"])
      );
      context.setInvPrisLabor(
        request.data.map((res, i) => request.data[i]["Prison Labor"])
      );
      context.setInvPrisLaborScore(
        request.data.map((res, i) => request.data[i]["Prison Labor Rating"])
      );
      context.setDarkMoneyData(
        request.data.map((res, i) => request.data[i]["DM Overall Rating"])
      );
      context.setDarkMoneyCPAScore(
        request.data.map((res, i) => request.data[i]["Dark Money"])
      );
      context.setClimateChangeData(
        request.data.map((res, i) => request.data[i]["CC Overall Score"])
      );
      context.setClean200Score(
        request.data.map((res, i) => request.data[i]["Asyousow Rating"])
      );
      context.setPresenceClean200Score(
        request.data.map(
          (res, i) => request.data[i]["As you sow Climate 200 Presence?"]
        )
      );
      context.setGreenHouseScore(
        request.data.map((res, i) => request.data[i]["CC Rating"])
      );
      context.setGoodJobScore(
        request.data.map((res, i) => request.data[i]["GoodJobsFirst"])
      );
      context.setHumanCapitalData(
        request.data.map((res, i) => request.data[i]["HC Overall Rating"])
      );
      context.setCeoPayRatio(
        request.data.map((res, i) => request.data[i]["CEO Rating"])
      );
      context.setPayRatioScore(
        request.data.map((res, i) => request.data[i]["CEO Pay Ratio"])
      );
      context.setCEOPayRatioScore(
        request.data.map((res, i) => request.data[i]["CEO Rating"])
      );
      context.setSHScore(
        request.data.map((res, i) => request.data[i]["SH Rating"])
      );
      context.setRacialJusticeData(
        request.data.map((res, i) => request.data[i]["RJ Overall Rating"])
      );
      context.setToxicEmissions(
        request.data.map((res, i) => request.data[i]["AP Toxic Rating"])
      );
      context.setAirPollutantScore(
        request.data.map(
          (res, i) => request.data[i]["Air Pollutants EJ Minority Share"]
        )
      );
      context.setGreenHouseEmissions(
        request.data.map((res, i) => request.data[i]["GreenHouse MS Rating"])
      );
      context.setGHPresence(
        request.data.map(
          (res, i) => request.data[i]["PERI Greenhouse 100 Presence"]
        )
      );
      context.setGreenHousePercentage(
        request.data.map(
          (res, i) => request.data[i]["GreenHouse Pollutants EJ Minority Share"]
        )
      );
      context.setShareholderData([
        ...context.shareholderData,
        request.data.map((res, i) => request.data[i]["DEF14A Link"]),
      ]);
      context.setShareholderData([
        ...context.shareholderData,
        request.data.map((res, i) => request.data[i]["Addendum #1"]),
      ]);
      context.setShareholderData([
        ...context.shareholderData,
        request.data.map((res, i) => request.data[i]["Addendum #2"]),
      ]);
      context.setShareholderData([
        ...context.shareholderData,
        request.data.map((res, i) => request.data[i]["Addendum #3"]),
      ]);
      context.setDataTable([
        ...context.dataTable,
        request.data.map((res, i) => request.data[i]["Overall Rating"]),
      ]);
      context.setDateSourced([
        ...context.dateSourced,
        request.data.map((res, i) => request.data[i]["Date Sourced"]),
      ]);
      context.setDateSourced([
        ...context.dateSourced,
        request.data.map((res, i) => request.data[i]["Date Sourced 1"]),
      ]);
      context.setDateSourced([
        ...context.dateSourced,
        request.data.map((res, i) => request.data[i]["Date Sourced 2"]),
      ]);
      context.setDateSourced([
        ...context.dateSourced,
        request.data.map((res, i) => request.data[i]["Date Sourced 3"]),
      ]);
      context.setDateSourced([
        ...context.dateSourced,
        request.data.map((res, i) => request.data[i]["Date Sourced 4"]),
      ]);
      context.setDateSourced([
        ...context.dateSourced,
        request.data.map((res, i) => request.data[i]["Date Sourced 5"]),
      ]);
      //   context.setShareholderData([...context.shareholderData,
      //     request.data.map((res, i) => request.data[i]["Addendum #4"])]
      //   );
      //   context.setShareholderData([...context.shareholderData,
      //     request.data.map((res, i) => request.data[i]["Addendum #5"])]
      //   );
      //   context.setShareholderData([...context.shareholderData,
      //     request.data.map((res, i) => request.data[i]["Addendum #6"])]
      //   );
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <View style={{ maxWidth: 1200, width: "95%", margin: "0 auto" }}>
      <View>
        <Logo
          style={{
            resizeMode: "contain",
            width: 250,
            height: 65,
            placeSelf: "center",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "2em",
          }}
        />
        <BackHomeButton onPress={() => context.setCompanyData([])} />
        {/* <Link to="/Home" onPress={() => context.setCompanyData([])}>
        Back Home
      </Link> */}
      </View>
      <View>
        <AboutCompany />
        <Text
          style={{
            color: "#FFFFFF",
            fontFamily: "Gilroy-Bold",
            fontSize: "clamp(32px, 2.8vw, 40px)",
          }}
        >
          Overall Scores
        </Text>
        {/* <Text style={{ color: "#FFFFFF" }}>{context.companyData}</Text> */}
        {/* <View>
      <Text style={{ color: "#FFFFFF" }}>Testing Image</Text>
          {OverallScore()}
      </View> */}
      </View>
      <View style={styles.cardBox}>
        <Pressable
          onPress={() => {
            modalBGColor(context.cardData);
            modalOSImage(context.cardData);
            context.setModalVisible(true);
            context.setModalText("Empowering Women");
            context.setCauseSlug("womenempowerment");
            context.setModalImage(WELogo);
            context.setDataTable([
              "50/50 Women on Board",
              "Target of >30% female representation on Board of Directors",
              context.modalScoreImage,
            ]);
          }}
          style={{
            textDecoration: "none",
            backgroundColor: "white",
            width: "clamp(280px, 45%, 327px)",
            flex: "auto",
            height: "auto",
            minWidth: 280,
            paddingLeft: 25,
            paddingBottom: 15,
            borderRadius: 10,
          }}
        >
          {/* <Link to="/womenempowerment"> */}
          <CardView
            onPress={() => {
            
              modalBGColor(context.cardData);
              modalOSImage(context.cardData);
              context.setModalVisible(true);
              context.setModalText("Empowering Women");
              context.setCauseSlug("womenempowerment");
              context.setModalImage(WELogo);
              context.setDataTable([
                "50/50 Women on Board",
                "Target of >30% female representation on Board of Directors",
                context.modalScoreImage,
              ]);
              
            }}
            title={"Empowering Women"}
            titleFontSize={12}
            style={{ padding: 0 }}
          >
            {{
              1: <Image source={greenCard} style={styles.coloredCorners} />,
              0: <Image source={yellowCard} style={styles.coloredCorners} />,
            }[context.cardData] || (
              <Image source={redCard} style={styles.coloredCorners} />
            )}
            <Image
              source={WELogo}
              style={{
                width: 130,
                height: 130,
                alignSelf: "flex-start",
                marginTop: 100,
              }}
            ></Image>
            <Text style={styles.cardViewTitle}>Empowering{"\n"}Women</Text>
            {/* <Text style={styles.cardViewDesc}>
              Female representation on Board:
              {context.cardData}
            </Text> */}
            <TouchableOpacity
              backgroundColor="transparent"
              style={styles.backHomeButton}
              onPress={() => {
                modalBGColor(context.cardData);
                modalOSImage(context.cardData);
                context.setModalVisible(true);
                context.setModalText("Empowering Women");
                context.setCauseSlug("womenempowerment");
                context.setModalImage(WELogo);
                context.setDataTable([
                  "50/50 Women on Board",
                  "Target of >30% female representation on Board of Directors",
                  context.modalScoreImage,
                ]);
                
              }}
            >
              <Text style={styles.backHomeText}>Read More</Text>
            </TouchableOpacity>
          </CardView>
          {/* </Link> */}
        </Pressable>
        <Pressable
          onPress={() => {
            console.log(context.prisonData);
            context.setModalVisible(true);
            modalBGColor(context.prisonData);
            modalOSImage(context.prisonData);
            context.setModalText("Prison Profiteering");
            context.setCauseSlug("prisonprofiteering");
            context.setModalImage(PPLogo);
            context.setDataTable([
              "Worth Rises",
              "Harm Score <10",
              context.harmScore,
            ]);
            context.setDataTable2([
              "Worth Rises",
              "No involvement in Immigration Detention Centers",
              context.immScore,
            ]);
            context.setDataTable3([
              "Worth Rises",
              "No involvement in Prison Labor",
              context.invPrisLaborScore,
            ]);
            console.log(context.invPrisLaborScore);
          }}
          style={{
            textDecoration: "none",
            backgroundColor: "white",
            width: "clamp(280px, 45%, 327px)",
            flex: "auto",
            height: "auto",
            minWidth: 280,
            paddingLeft: 25,
            paddingBottom: 15,
            borderRadius: 10,
          }}
        >
          <CardView
            onPress={() => {
              console.log(context.prisonData);
              context.setModalVisible(true);
              modalBGColor(context.prisonData);
              modalOSImage(context.prisonData);
              context.setModalText("Prison Profiteering");
              context.setCauseSlug("prisonprofiteering");
              context.setModalImage(PPLogo);
              context.setDataTable([
                "Worth Rises",
                "Harm Score <10",
                context.harmScore,
              ]);
              context.setDataTable2([
                "Worth Rises",
                "No involvement in Immigration Detention Centers",
                context.immScore,
              ]);
              context.setDataTable3([
                "Worth Rises",
                "No involvement in Prison Labor",
                context.harmScore,
              ]);
            }}
            title={"Prison Profiteering"}
            titleFontSize={12}
            style={{ padding: 0 }}
            // style={styles.cardView}
          >
            {{
              1: <Image source={greenCard} style={styles.coloredCorners} />,
              0: <Image source={yellowCard} style={styles.coloredCorners} />,
            }[context.prisonData] || (
              <Image source={redCard} style={styles.coloredCorners} />
            )}
            <Image
              source={PPLogo}
              style={{
                width: 130,
                height: 130,
                alignSelf: "flex-start",
                marginTop: 100,
              }}
            ></Image>
            <Text style={styles.cardViewTitle}>Prison{"\n"}Profiteering</Text>
            {/* <Text style={styles.cardViewDesc}>
              Prison Labor:
              {context.prisonData}
            </Text> */}
            <TouchableOpacity
              backgroundColor="transparent"
              style={styles.backHomeButton}
              onPress={() => {
                console.log(context.prisonData);
                context.setModalVisible(true);
                modalBGColor(context.prisonData);
                modalOSImage(context.prisonData);
                context.setModalText("Prison Profiteering");
                context.setCauseSlug("prisonprofiteering");
                context.setModalImage(PPLogo);
                context.setDataTable([
                  "Worth Rises",
                  "Harm Score <10",
                  context.harmScore,
                ]);
                context.setDataTable2([
                  "Worth Rises",
                  "No involvement in Immigration Detention Centers",
                  context.immScore,
                ]);
                context.setDataTable3([
                  "Worth Rises",
                  "No involvement in Prison Labor",
                  context.harmScore,
                ]);
              }}
            >
              <Text style={styles.backHomeText}>Read More</Text>
            </TouchableOpacity>
          </CardView>
        </Pressable>
        <Pressable
          onPress={() => {
            context.setModalVisible(true);
            modalBGColor(context.darkMoneyData);
            modalOSImage(context.darkMoneyData);
            context.setModalText("Dark Money");
            context.setCauseSlug("darkmoney");
            context.setModalImage(DMLogo);
            context.setDataTable([
              "CPA Zicklin Index",
              "Target score of >90",
              context.darkMoneyData,
            ]);
          }}
          style={{
            textDecoration: "none",
            backgroundColor: "white",
            width: "clamp(280px, 45%, 327px)",
            flex: "auto",
            height: "auto",
            minWidth: 280,
            paddingLeft: 25,
            paddingBottom: 15,
            borderRadius: 10,
          }}
        >
          <CardView
            onPress={() => {
              context.setModalVisible(true);
              modalBGColor(context.darkMoneyData);
              modalOSImage(context.darkMoneyData);
              context.setModalText("Dark Money");
              context.setCauseSlug("darkmoney");
              context.setModalImage(DMLogo);
              context.setDataTable([
                "CPA Zicklin Index",
                "Target score of >90",
                context.darkMoneyData,
              ]);
            }}
            title={"Dark Money"}
            titleFontSize={12}
            style={{ padding: 0 }}
          >
            {{
              "-1": <Image source={redCard} style={styles.coloredCorners} />,
              0: <Image source={yellowCard} style={styles.coloredCorners} />,
            }[context.darkMoneyData] || (
              <Image source={greenCard} style={styles.coloredCorners} />
            )}
            <Image
              source={DMLogo}
              style={{
                width: 130,
                height: 130,
                alignSelf: "flex-start",
                marginTop: 100,
              }}
            ></Image>
            <Text style={styles.cardViewTitle}>Dark{"\n"}Money</Text>
            {/* <Text style={styles.cardViewDesc}>
              Dark Money Score:
              {context.darkMoneyData}
            </Text> */}
            <TouchableOpacity
              backgroundColor="transparent"
              style={styles.backHomeButton}
              onPress={() => {
                context.setModalVisible(true);
                modalBGColor(context.darkMoneyData);
                modalOSImage(context.darkMoneyData);
                context.setModalText("Dark Money");
                context.setCauseSlug("darkmoney");
                context.setModalImage(DMLogo);
                context.setDataTable([
                  "CPA Zicklin Index",
                  "Target score of >90",
                  context.darkMoneyData,
                ]);
              }}
            >
              <Text style={styles.backHomeText}>Read More</Text>
            </TouchableOpacity>
          </CardView>
        </Pressable>
        <Pressable
          onPress={() => {
            context.setModalVisible(true);
            modalBGColor(context.climateChangeData);
            modalOSImage(context.climateChangeData);
            context.setModalText("Climate Change");
            context.setCauseSlug("climatechange");
            context.setModalImage(CCLogo);
            context.setDataTable([
              "As You Sow",
              "Presence on Clean 200",
              context.clean200Score,
            ]);
            context.setDataTable2([
              "PERI Institute",
              "Not present on Greenhouse 100",
              context.greenHouseScore,
            ]);
            context.setDataTable3([
              "GoodJobs1st",
              "Less than sector average of fines and penalties for environmental violations",
              context.goodJobScore,
            ]);
          }}
          style={{
            textDecoration: "none",
            backgroundColor: "white",
            width: "clamp(280px, 45%, 327px)",
            flex: "auto",
            height: "auto",
            minWidth: 280,
            paddingLeft: 25,
            paddingBottom: 15,
            borderRadius: 10,
          }}
        >
          <CardView
            onPress={() => {
              context.setModalVisible(true);
              modalBGColor(context.climateChangeData);
              modalOSImage(context.climateChangeData);
              context.setModalText("Climate Change");
              context.setCauseSlug("climatechange");
              context.setModalImage(CCLogo);
              context.setDataTable([
                "As You Sow",
                "Presence on Clean 200",
                context.clean200Score,
              ]);
              context.setDataTable2([
                "PERI Institute",
                "Not present on Greenhouse 100",
                context.greenHouseScore,
              ]);
              context.setDataTable3([
                "GoodJobs1st",
                "Less than sector average of fines and penalties for environmental violations",
                context.goodJobScore,
              ]);
            }}
            title={"Climate Change"}
            titleFontSize={12}
            style={{ padding: 0 }}
          >
            {{
              1: <Image source={greenCard} style={styles.coloredCorners} />,
              0: <Image source={yellowCard} style={styles.coloredCorners} />,
            }[context.climateChangeData] || (
              <Image source={redCard} style={styles.coloredCorners} />
            )}
            <Image
              source={CCLogo}
              style={{
                width: 130,
                height: 130,
                alignSelf: "flex-start",
                marginTop: 100,
              }}
            ></Image>
            <Text style={styles.cardViewTitle}>Climate{"\n"}Change</Text>
            {/* <Text style={styles.cardViewDesc}>
              Climate Change Score:
              {context.climateChangeData}
            </Text> */}
            <TouchableOpacity
              backgroundColor="transparent"
              style={styles.backHomeButton}
              onPress={() => {
                context.setModalVisible(true);
                modalBGColor(context.climateChangeData);
                modalOSImage(context.climateChangeData);
                context.setModalText("Climate Change");
                context.setCauseSlug("climatechange");
                context.setModalImage(CCLogo);
                context.setDataTable([
                  "As You Sow",
                  "Presence on Clean 200",
                  context.clean200Score,
                ]);
                context.setDataTable2([
                  "PERI Institute",
                  "Not present on Greenhouse 100",
                  context.greenHouseScore,
                ]);
                context.setDataTable3([
                  "GoodJobs1st",
                  "Less than sector average of fines and penalties for environmental violations",
                  context.goodJobScore,
                ]);
              }}
            >
              <Text style={styles.backHomeText}>Read More</Text>
            </TouchableOpacity>
          </CardView>
        </Pressable>
        <Pressable
          onPress={() => {
            context.setModalVisible(true);
            modalBGColor(context.humanCapitalData);
            modalOSImage(context.humanCapitalData);
            context.setModalText("People Over Profits");
            context.setCauseSlug("humancapital");
            context.setModalImage(HCLogo);
            context.setDataTable([
              "CEO Pay Ratio",
              "Target : 150:1 Maximum of 299:1 for neutral",
              context.ceoPayRatio,
            ]);
            context.setDataTable2([
              "ForceTheIssue",
              "Does not require arbitration for Sexual Harassment claims",
              context.sHScore,
            ]);
          }}
          style={{
            textDecoration: "none",
            backgroundColor: "white",
            width: "clamp(280px, 45%, 327px)",
            flex: "auto",
            height: "auto",
            minWidth: 280,
            paddingLeft: 25,
            paddingBottom: 15,
            borderRadius: 10,
          }}
        >
          <CardView
            onPress={() => {
              context.setModalVisible(true);
              modalBGColor(context.humanCapitalData);
              modalOSImage(context.humanCapitalData);
              context.setModalText("People Over Profits");
              context.setCauseSlug("humancapital");
              context.setModalImage(HCLogo);
              context.setDataTable([
                "CEO Pay Ratio",
                "Target : 150:1 Maximum of 299:1 for neutral",
                context.ceoPayRatio,
              ]);
              context.setDataTable2([
                "ForceTheIssue",
                "Does not require arbitration for Sexual Harassment claims",
                context.sHScore,
              ]);
            }}
            title={"Human Capital"}
            titleFontSize={12}
            style={{ padding: 0 }}
          >
            {{
              1: <Image source={greenCard} style={styles.coloredCorners} />,
              0: <Image source={yellowCard} style={styles.coloredCorners} />,
            }[context.humanCapitalData] || (
              <Image source={redCard} style={styles.coloredCorners} />
            )}
            <Image
              source={HCLogo}
              style={{
                width: 130,
                height: 130,
                alignSelf: "flex-start",
                marginTop: 100,
              }}
            ></Image>
            <Text style={styles.cardViewTitle}>People{"\n"}Over Profits</Text>
            {/* <Text style={styles.cardViewDesc}>
              Human Capital:
              {context.humanCapitalData}
            </Text> */}
            <TouchableOpacity
              backgroundColor="transparent"
              style={styles.backHomeButton}
              onPress={() => {
                context.setModalVisible(true);
                modalBGColor(context.humanCapitalData);
                modalOSImage(context.humanCapitalData);
                context.setModalText("People Over Profits");
                context.setCauseSlug("humancapital");
                context.setModalImage(HCLogo);
                context.setDataTable([
                  "CEO Pay Ratio",
                  "Target : 150:1 Maximum of 299:1 for neutral",
                  context.ceoPayRatio,
                ]);
                context.setDataTable2([
                  "ForceTheIssue",
                  "Does not require arbitration for Sexual Harassment claims",
                  context.sHScore,
                ]);
              }}
            >
              <Text style={styles.backHomeText}>Read More</Text>
            </TouchableOpacity>
          </CardView>
        </Pressable>
        <Pressable
          onPress={() => {
            context.setModalVisible(true);
            modalBGColor(context.racialJusticeData);
            modalOSImage(context.racialJusticeData);
            context.setModalText("Racial Justice");
            context.setCauseSlug("racialjustice");
            context.setModalImage(RJLogo);
            context.setDataTable([
              "Peri Toxic Database",
              "The toxic burden on minority communities is <39%",
              context.toxicEmissions,
            ]);
            context.setDataTable2([
              "Peri Greenhouse Database",
              "<39% of greenhouse gases polluting minority communities",
              context.greenHouseEmissions,
            ]);
          }}
          style={{
            textDecoration: "none",
            backgroundColor: "white",
            width: "clamp(280px, 45%, 327px)",
            flex: "auto",
            height: "auto",
            minWidth: 280,
            paddingLeft: 25,
            paddingBottom: 15,
            borderRadius: 10,
          }}
        >
          <CardView
            onPress={() => {
              context.setModalVisible(true);
              modalBGColor(context.racialJusticeData);
              modalOSImage(context.racialJusticeData);
              context.setModalText("Racial Justice");
              context.setCauseSlug("racialjustice");
              context.setModalImage(RJLogo);
              context.setDataTable([
                "Peri Toxic Database",
                "The toxic burden on minority communities is <39%",
                context.toxicEmissions,
              ]);
              context.setDataTable2([
                "Peri Greenhouse Database",
                "<39% of greenhouse gases polluting minority communities",
                context.greenHouseEmissions,
              ]);
            }}
            title={"Racial Justice"}
            titleFontSize={12}
            style={{ padding: 0 }}
          >
            {{
              1: <Image source={greenCard} style={styles.coloredCorners} />,
              0: <Image source={yellowCard} style={styles.coloredCorners} />,
            }[context.racialJusticeData] || (
              <Image source={redCard} style={styles.coloredCorners} />
            )}
            <Image
              source={RJLogo}
              style={{
                width: 130,
                height: 130,
                alignSelf: "flex-start",
                marginTop: 100,
              }}
            ></Image>
            <Text style={styles.cardViewTitle}>Racial{"\n"}Justice</Text>
            {/* <Text style={styles.cardViewDesc}>
              Racial Justice:
              {context.racialJusticeData}
            </Text> */}
            <TouchableOpacity
              backgroundColor="transparent"
              style={styles.backHomeButton}
              onPress={() => {
                context.setModalVisible(true);
                modalBGColor(context.racialJusticeData);
                modalOSImage(context.racialJusticeData);
                context.setModalText("Racial Justice");
                context.setCauseSlug("racialjustice");
                context.setModalImage(RJLogo);
                context.setDataTable([
                  "Peri Toxic Database",
                  "The toxic burden on minority communities is <39%",
                  context.toxicEmissions,
                ]);
                context.setDataTable2([
                  "Peri Greenhouse Database",
                  "<39% of greenhouse gases polluting minority communities",
                  context.greenHouseEmissions,
                ]);
              }}
            >
              <Text style={styles.backHomeText}>Read More</Text>
            </TouchableOpacity>
          </CardView>
        </Pressable>
      </View>
      {/* <View>
        <Text
          style={{
            textAlign: "left",
            color: "#FFFFFF",
            fontSize: 40,
            fontFamily: "Gilroy-Bold",
            marginBottom: 25,
          }}
        >
          Active Shareholder Resolutions
        </Text>
        <Pressable onPress={() => console.log(context.shareholderData)}>
          <Text
            style={{
              color: "#FFFFFF",
              fontFamily: "Typekit",
              fontSize: 18,
              paddingBottom: 20,
              marginBottom: 20,
              borderBottom: "1px solid white",
            }}
          >
            {context.shareholderData}
          </Text>
        </Pressable>
      </View> */}
      <Modal
        animationType="fade"
        transparent={true}
        visible={context.modalVisible}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          context.setModalVisible(!context.modalVisible);
        }}
        style={{
          flex: "none",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          position: "absolute",
        }}
      >
        <TouchableWithoutFeedback
          onPress={() => {
            context.setModalVisible(!context.modalVisible);
                  context.setDataTable([]);
                  context.setDataTable2([]);
                  context.setDataTable3([]);
                  context.setModalBGStyle([]);
                  context.setModalCloseColor("");
                  context.setModalScoreImage("");
                  context.setTableScoreImage("");
                  context.setTableScoreImage2("");
                  context.setTableScoreImage3("");
                  context.setSource1("");
                  context.setSource2("");
                  context.setSource3("");
                  context.setScoreNumber("");
                  context.setScoreNumber2("");
                  context.setScoreNumber3("");
                  context.setMeetsRequirementsWording("");
                  context.setMeetsRequirementsWording2("");
                  context.setMeetsRequirementsWording3("");
                  context.setCauseSlug("");
          }}
          style={{ position: "absolute" }}
        >
          <View style={styles.centeredView}>
            <View style={context.modalBGStyle}>
              <Pressable
                style={[styles.button, styles.buttonClose]}
                onPress={() => {
                  context.setModalVisible(!context.modalVisible);
                  context.setDataTable([]);
                  context.setDataTable2([]);
                  context.setDataTable3([]);
                  context.setModalBGStyle([]);
                  context.setModalCloseColor("");
                  context.setModalScoreImage("");
                  context.setTableScoreImage("");
                  context.setTableScoreImage2("");
                  context.setTableScoreImage3("");
                  context.setSource1("");
                  context.setSource2("");
                  context.setSource3("");
                  context.setScoreNumber("");
                  context.setScoreNumber2("");
                  context.setScoreNumber3("");
                  context.setMeetsRequirementsWording("");
                  context.setMeetsRequirementsWording2("");
                  context.setMeetsRequirementsWording3("");
                  context.setCauseSlug("");
                }}
              >
                <Icon.Button
                  name={"close"}
                  style={context.modalCloseColor}
                  onPress={() => {
                    context.setModalVisible(!context.modalVisible);
                    context.setDataTable([]);
                    context.setDataTable2([]);
                    context.setDataTable3([]);
                    context.setModalBGStyle([]);
                    context.setModalCloseColor("");
                    context.setModalScoreImage("");
                    context.setTableScoreImage("");
                    context.setTableScoreImage2("");
                    context.setTableScoreImage3("");
                    context.setSource1("");
                    context.setSource2("");
                    context.setSource3("");
                    context.setScoreNumber("");
                    context.setScoreNumber2("");
                    context.setScoreNumber3("");
                    context.setMeetsRequirementsWording("");
                  context.setMeetsRequirementsWording2("");
                  context.setMeetsRequirementsWording3("");
                  context.setCauseSlug("");
                  }}
                ></Icon.Button>
              </Pressable>
              <View style={styles.modalImgContainer}>
                <Image
                  source={context.modalImage}
                  style={{
                    maxHeight: 300,
                    width: "100%",
                    height: "100%",
                    maxWidth: 300,
                    minWidth: 200,
                    minHeight: 200,
                  }}
                ></Image>
              </View>
              <View style={styles.modalContent}>
                {/* <Image
                  source={context.modalScoreImage}
                  style={styles.coloredCorners}
                /> */}
                <View style={styles.modalContent}>
                <Link
                to={`/${context.causeSlug}`}
                style={{ textDecoration: "none", flex: "31%" }}
              >
                  <Text style={styles.modalText}>{context.modalText}</Text>
                  </Link>
                  <View style={styles.modalScore}>
                    <Text style={styles.modalSubText}>Overall Score:</Text>
                    <Image
                      source={context.modalScoreImage}
                      style={{
                        maxWidth: 30,
                        minWidth: 30,
                        minHeight: 30,
                        maxHeight: 30,
                      }}
                    ></Image>
                  </View>
                </View>
                {/* <Icon name={'check-circle'} color="white">Overall Score:</Icon> */}
                {/* <Text>`Date Sourced: {context.dateSourced[3]}`</Text> */}
                <CompanyTable />
              </View>
              <View
                style={styles.modalContent}
              >
                <Link
                to={`/FAQ`}
                style={{ textDecoration: "none", flex: "31%" }}
              >
                  <Text
                    style={{
                      color: "#FFFFFF",
                      fontFamily: "Typekit",
                      fontSize: 18,
                      paddingBottom: 20,
                      marginBottom: 20,
                      // borderBottom: "1px solid white",
                      justifyContent: "center",
                    }}
                  >
                    Methodology
                  </Text>
                </Link>
              </View>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
      <Footer />
    </View>
  );
};

export default CompanyDetails;

const styles = StyleSheet.create({
  container: {
    padding: 30,
    flex: 1,
    backgroundColor: "#1A1C22",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
  },
  backHomeText: {
    alignSelf: "center",
    fontSize: 15,
    color: "black",
    fontFamily: "Gilroy-Bold",
    letterSpacing: 1,
    textAlign: "center",
  },
  backHomeButton: {
    color: "black",
    borderWidth: 2,
    borderColor: "black",
    borderRadius: 5,
    height: 40,
    width: 180,
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
    marginTop: 10,
  },
  cardBox: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 0,
    paddingRight: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    flex: "auto",
    marginBottom: 55,
    gap: "2.5em 1.5vw",
    //gap: "1.5vw",
  },
  coloredCorners: {
    width: 130,
    height: 130,
    alignSelf: "flex-end",
    position: "absolute",
    right: 0,
  },
  cardView: {
    flex: "31%",
  },
  cardViewTitle: {
    fontFamily: "Gilroy-Bold",
    fontSize: 32,
    letterSpacing: 2,
  },
  cardViewDesc: {
    marginTop: 10,
    textAlign: "left",
    fontFamily: "Typekit",
  },
  description: {
    padding: 10,
    textAlign: "center",
  },
  title: {
    textAlign: "center",
  },
  itemStyle: {
    padding: 10,
    color: "#FFFFFF",
  },
  centeredView: {
    flex: "none",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.8)",
  },
  closeButtonYellow: {
    backgroundColor: "#E9B44B",
  },
  closeButtonGreen: {
    backgroundColor: "#688E26",
  },
  closeButtonRed: {
    backgroundColor: "#972224",
  },
  modalView: {
    backgroundColor: "#972224",
    margin: 20,
    borderRadius: 20,
    padding: "clamp(6px, 3%, 35px)",
    maxWidth: 960,
    width: "100%",
    height: "auto",
    minHeight: 550,
    alignItems: "flex-start",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    alignItems: "flex-start",
    flex: "none",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  modalImgContainer: {
    alignSelf: "flex-start",
    top: "-7vw",
    left: 0,
    width: "25%",
    minWidth: 200,
    flexBasis: 0,
  },
  modalContent: {
    alignItems: "flex-start",
    width: "70%",
    minWidth: 360,
  },
  modalViewYellow: {
    backgroundColor: "#E9B44B",
    margin: 20,
    borderRadius: 20,
    padding: "clamp(6px, 3%, 35px)",
    maxWidth: 960,
    width: "100%",
    height: "auto",
    maxHeight: 600,
    minHeight: 550,
    alignItems: "flex-start",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    alignItems: "flex-start",
    flex: "none",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  modalViewGreen: {
    backgroundColor: "#688E26",
    margin: 20,
    borderRadius: 20,
    padding: "clamp(6px, 3%, 35px)",
    maxWidth: 960,
    width: "100%",
    height: "auto",
    minHeight: 550,
    maxHeight: 600,
    alignItems: "flex-start",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    alignItems: "flex-start",
    flex: "none",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    position: "absolute",
    top: 0,
    right: 0,
    margin: "1em",
    backgroundColor: "transparent",
  },
  buttonOpen: {
    backgroundColor: "transparent",
  },
  buttonClose: {
    backgroundColor: "transparent",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "left",
    fontFamily: "Gilroy-Bold",
    color: "white",
    fontSize: 38,
    marginLeft: 15,
  },
  modalSubText: {
    textAlign: "left",
    fontFamily: "Gilroy-Bold",
    color: "white",
    fontSize: 20,
    placeSelf: "center",
    marginLeft: 15,
  },
  tableView: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    fontSize: 14,
    fontFamily: "Typekit",
  },
  modalScore: {
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    gap: 20,
  },
});
