import React, { useContext, useState, useEffect } from "react";
import Helmet from 'react-helmet';
import { View, Text, Modal, StyleSheet, Pressable, Image } from "react-native";
import { BrowserRouter as Router, Link } from "react-router-dom";
import AppContext from "./AppContext";
import Logo from "./Logo";
import axios from "axios";
import WELogo from "../assets/women-empowerment.png";
import InteriorBG from "../assets/interior-bg.png";
import Footer from "./Footer";
import BackHomeButton from "./BackHomeButton";
import Infographics from "./Infographics";
import WEInfo from "../assets/women-empowerment-infographic.png";
import Sharingoption from "./SharignOptions";
// import Home from './Home'

const WomenEmpowerment = () => {
    const context = useContext(AppContext);
    const [seoData, setSeo] = useState([]);
    
    const getSeoData = async () => {
      try {
        const seo = await axios.get(
          `${ process.env.APP_MANIFEST.Local_URL}seo?pageName=Empowering`
        );
        setSeo(
          seo?.data[0]
        );
      } catch (error) {
        // handle error
        alert(error);
      }
    }
    
    useEffect(() => {
      if (seoData.length === 0) {
          console.log("Getting SEO Data");
        getSeoData();
      }
    });
    
    return (
      <>
        <Helmet>
        <meta charset="utf-8" />
        <meta httpequiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1.00001, viewport-fit=cover" />
            <meta name="description" content={seoData.metaDesc} />
            <meta name="keywords" content={seoData.metaKeywords} />
        <title>{seoData.pageTitle}</title>
      </Helmet><View style={styles.container}>
      <Logo
        style={{
          resizeMode: "contain",
          width: 250,
          height: 65,
          placeSelf: "center",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "2em",
        }}
      />
      <View style={styles.innerContainer}>
        <BackHomeButton />
        {/* <Text style={{color: "#FFFFFF"}}>Women Empowerment</Text> */}
        {/* <Sharingoption /> */}
        <View style={styles.innerPageContainer}>
        <Sharingoption />
          <Image
            source={WELogo}
            style={{
              width: "clamp(320px, 400px, 24vw)",
              height: "clamp(320px, 400px, 23vw)",
            }}
          />
          <View style={styles.paragraph}>
            <Text
              style={{
                textAlign: "left",
                color: "#FFFFFF",
                fontSize: "clamp(45px, 3.2vw, 62px)",
                fontFamily: "Gilroy-Bold",
              }}
            >
              Empowering Women
            </Text>
            <Text
              style={{
                textAlign: "left",
                color: "#FFFFFF",
                fontSize: 24,
                lineHeight: "1.7em",
                marginTop: "1.7em",
                fontFamily: "Typekit",
                paddingBottom: "1.7em",
                borderBottom: "1px solid white",
              }}
            >
              {`“Women’s empowerment can be defined as promoting women’s sense of self-worth, their ability to determine their own choices, and their right to influence social change for themselves and others.”
`}
              <Link
                to={{ pathname: "https://www.worldvision.com.au/womens-empowerment" }}
                target="_blank"
                style={{ textDecoration: "none", flex: "31%" }}
              >
                <Text style={styles.linkStyle}>www.worldvision.com.au/womens-empowerment</Text>
              </Link>

            </Text>

            <Infographics
              style={{
                resizeMode: "contain",
                maxWidth: 800,
                width: "100%",
                height: 600,
                placeSelf: "center",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "2em",
              }}
              source={WEInfo}
            />
            <Text
              style={{
                textAlign: "left",
                color: "#FFFFFF",
                fontSize: 24,
                lineHeight: "1.7em",
                marginTop: "1.7em",
                fontFamily: "Typekit",
              }}
            >
              {`More CEOs are named John in the S&P 500 than there are female CEOs.

Women make up almost 50% of the workforce, though only 6% of S&P 500 corporations have female CEOs.

Only eleven S&P 500 corporate boards have achieved gender parity.
`}
            </Text>
            <Text
              style={{
                textAlign: "left",
                color: "#FFFFFF",
                fontSize: 24,
                lineHeight: "1.7em",
                marginTop: "1.7em",
                fontFamily: "Typekit",
              }}
            >
              {`In the words of the Queen, Beyonce: “Who runs the world? Girls!” Women do run this world. While women are often tasked with the important tasks of birthing and raising children, along with running a household, and all that entails, they are often treated as inferiors in the workplace. We have made great strides towards equality, but there is still much work to be done.`}
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.centeredView}>
        {/* <Modal
        animationType="slide"
        transparent={true}
        visible={context.modalVisible}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          context.setModalVisible(!context.modalVisible);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>Hello World!</Text>
            <Pressable
              style={[styles.button, styles.buttonClose]}
              onPress={() => context.setModalVisible(!context.modalVisible)}
            >
              <Text style={styles.textStyle}>Hide Modal</Text>
            </Pressable>
          </View>
        </View>
      </Modal> */}
        {/* <Pressable
        style={[styles.button, styles.buttonOpen]}
        onPress={() => context.setModalVisible(true)}
      >
        <Text style={styles.textStyle}>Show Modal</Text>
      </Pressable> */}
      </View>
      <Footer />
    </View></>
  );
};
const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  container: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    // backgroundImage: `url(${InteriorBG})`
  },
  innerContainer: {
    maxWidth: 1200,
    width: "100%",
    margin: "0 auto",
  },
  innerPageContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row-reverse",
    // justifyContent: "space-around",
    justifyContent:'space-between',
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  paragraph: {
    width: "clamp(65%, 100%, 780px)",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
  linkStyle: {
    textAlign: "left",
    color: "#FFFFFF",
    fontSize: 24,
    lineHeight: "1.7em",
    marginTop: "1.7em",
    fontFamily: "Typekit",
    fontWeight: "bold",
    textDecorationLine: "none"
  },
});
export default WomenEmpowerment;
