import React, { useContext, useState, useEffect } from "react";
import Helmet from 'react-helmet';
import { View, Text, Modal, StyleSheet, Pressable, Image } from "react-native";
import { BrowserRouter as Router, Link } from "react-router-dom";
import AppContext from "./AppContext";
import axios from "axios";
import Logo from "./Logo";
import PPLogo from "../assets/prisoner-profiteering.png";
import Footer from "./Footer";
import BackHomeButton from "./BackHomeButton";
import PPInfo from "../assets/prisoner-profiteering-infographic.png";
import Infographic from "./Infographics";
import Sharingoption from './SharignOptions' 
// import Home from './Home'

const PrisonProfiteering = () => {
  const context = useContext(AppContext);
    const [seoData, setSeo] = useState([]);
    
    const getSeoData = async () => {
      try {
        const seo = await axios.get(
          `${ process.env.APP_MANIFEST.Local_URL}seo?pageName=Prison`
        );
        setSeo(
          seo?.data[0]
        );
      } catch (error) {
        // handle error
        alert(error);
      }
    }
    
    useEffect(() => {
      if (seoData.length === 0) {
          console.log("Getting SEO Data");
        getSeoData();
      }
    });
    
    return (
      <>
      <Helmet>
        <meta charset="utf-8" />
        <meta httpequiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1.00001, viewport-fit=cover" />
            <meta name="description" content={seoData.metaDesc} />
            <meta name="keywords" content={seoData.metaKeywords} />
        <title>{seoData.pageTitle}</title>
      </Helmet><View style={styles.container}>
     
      <Logo
        style={{
          resizeMode: "contain",
          width: 250,
          height: 65,
          placeSelf: "center",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "2em",
        }}
      />
      <View style={styles.innerContainer}>
        <BackHomeButton />
        {/* <Text style={{color: "#FFFFFF"}}>Women Empowerment</Text> */}
        <View style={styles.innerPageContainer}>
        <Sharingoption />
          <Image
            source={PPLogo}
            style={{
              width: "clamp(320px, 400px, 24vw)",
              height: "clamp(320px, 400px, 23vw)",
            }}
          />
          <View style={styles.paragraph}>
            <Text
              style={{
                textAlign: "left",
                color: "#FFFFFF",
                fontSize: "clamp(45px, 3.2vw, 62px)",
                fontFamily: "Gilroy-Bold",
              }}
            >
              Prison Profiteering
            </Text>
            <Text
              style={{
                textAlign: "left",
                color: "#FFFFFF",
                fontSize: 24,
                lineHeight: "1.7em",
                marginTop: "1.7em",
                fontFamily: "Typekit",
                paddingBottom: "1.7em",
                borderBottom: "1px solid white",
              }}
            >
              {`Profiteering, according to Merriam-Webster dictionary, is “the act or activity of making unreasonable profit on the sale of essential goods…”. This occurs in prisons, not only on the sales of essential goods, but in the utilization of inmates for cheap labor on high profit products like US military helmets.`}
            </Text>
            <Infographic
              style={{
                resizeMode: "contain",
                maxWidth: 800,
                width: "100%",
                height: 600,
                placeSelf: "center",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "2em",
              }}
              source={PPInfo}
            />
            <Text
              style={{
                textAlign: "left",
                color: "#FFFFFF",
                fontSize: 24,
                lineHeight: "1.7em",
                marginTop: "1.7em",
                fontFamily: "Typekit",
              }}
            >
              {`As the prison population has grown, so has the bail bond industry, now earning about $3 billion in profits every year.

Prisoners are can be charged as much as $17 for a 15-minute phone call – that’s $1.13 per minute!

State-run prisons generate millions in profits, making prison labor an industry of more than $1 billion.
`}
            </Text>
          </View>
        </View>
      </View>
      <Footer />
    </View></>
  );
};
const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  container: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    // backgroundImage: `url(${InteriorBG})`
  },
  innerContainer: {
    maxWidth: 1200,
    width: "100%",
    margin: "0 auto",
  },
  innerPageContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row-reverse",
    // justifyContent: "space-around",
    justifyContent:'space-between',
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  paragraph: {
    width: "clamp(65%, 100%, 780px)",
  },
});
export default PrisonProfiteering;
