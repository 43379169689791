import { StatusBar } from "expo-status-bar";
import React, { useContext, useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
  Button,
  TextInput,
  ScrollView,
  FlatList,
  Image,
  TouchableOpacity,
  Pressable,
} from "react-native";
import { SocialIcon } from 'react-native-elements';
// import { NativeRouter, Route, Link } from "react-router-native";
import CompanyItem from "./components/CompanyItem";
import CompanyInput from "./components/CompanyInput";
import Logo from "./components/Logo";
import AboutCompany from "./components/AboutCompany";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from "./components/Home";
import Navi from "./components/shared/Navi";
import WomenEmpowerment from "./components/WomenEmpowerment";
import PrisonProfiteering from "./components/PrisonProfiteering";
import CompanyDetails from "./components/CompanyDetails";
import Menu from "./components/Menu";
import UnderConstruction from "./components/UnderConstruction";
import axios from "axios";
import { AppProvider } from "./components/AppContext";
import HomeBG from "./assets/home-bg.png";
import Icon from "react-native-vector-icons/FontAwesome";
import ClimateChange from "./components/ClimateChange";
import RacialJustice from "./components/RacialJustice";
import DarkMoney from "./components/DarkMoney";
import PeopleOverProfits from "./components/POP";
import AppLoading from "expo-app-loading";
import { useFonts } from "expo-font";
import ContactPage from "./components/ContactPage";
import AboutUs from "./components/AboutUs";
import ForAdvisors from "./components/ForAdvisors";
import TermsAndConditions from "./components/TermsAndConditions";
import FAQ from "./components/FAQ";
import {screen_name } from './components/FirebaseConfig/firebaseconfig.js'
import { getAnalytics, logEvent } from "firebase/analytics";

import TagManager from 'react-gtm-module';

const gtmId = process.env.APP_MANIFEST.GTM_ID;
export default function App() {
  document.body.style = "background: #000B28;";
  let [fontsLoaded] = useFonts({
    "Gilroy-Regular": require("./assets/fonts/GilroyRegular/font.woff"),
    "Gilroy-Bold": require("./assets/fonts/GilroyBold/font.woff"),
    TypeKit:
      "https://use.typekit.net/af/a727c9/00000000000000007735af8e/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3",
    TypeKit2:
      "https://use.typekit.net/af/46ebd4/00000000000000007735af97/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3",
  });


  // const Home = () => <Text style={styles.header}>Home</Text>;
  const [companies, setCompanies] = useState([]);
  const [clickedCompany, setClickedCompany] = useState("");
  const [companyData, setCompanyData] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [prisonData, setPrisonData] = useState([]);
  const [darkMoneyData, setDarkMoneyData] = useState([]);
  const [climateChangeData, setClimateChangeData] = useState([]);
  const [humanCapitalData, setHumanCapitalData] = useState([]);
  const [racialJusticeData, setRacialJusticeData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [shareholderData, setShareholderData] = useState([]);
  const [menuState, setMenuState] = useState(false);
  const [menuWording, setMenuWording] = useState("MENU");
  const [menuIcon, setMenuIcon] = useState("bars");
  const [menuColor, setMenuColor] = useState("#09ccc7");
  const [menuBackground, setMenuBackground] = useState("white");
  const [menuWordingStyle, setMenuWordingStyle] = useState(styles.menuOpenText);
  const [tableHead, setTableHead] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [modalText, setModalText] = useState("");
  const [dataTable, setDataTable] = useState([]);
  const [dataTable2, setDataTable2] = useState([]);
  const [dataTable3, setDataTable3] = useState([]);
  const [harmScore, setHarmScore] = useState([]);
  const [immScore, setImmScore] = useState([]);
  const [prisonLaborScore, setPrisonLaborScore] = useState([]);
  const [clean200Score, setClean200Score] = useState([]);
  const [greenHouseScore, setGreenHouseScore] = useState([]);
  const [goodJobScore, setGoodJobScore] = useState([]);
  const [ceoPayRatio, setCeoPayRatio] = useState([]);
  const [sHScore, setSHScore] = useState([]);
  const [greenHouseEmissions, setGreenHouseEmissions] = useState([]);
  const [toxicEmissions, setToxicEmissions] = useState([]);
  const [dateSourced, setDateSourced] = useState([]);
  const [modalImage, setModalImage] = useState([]);
  const [modalBGStyle, setModalBGStyle] = useState("red");
  const [modalCloseColor, setModalCloseColor] = useState("red");
  const [modalScoreImage, setModalScoreImage] = useState("");
  const [sources, setSources] = useState([
    "https://5050wob.com/directory/?companyname=&city=&noofwomen=&sector=&state=&rating=",
    "https://www.sec.gov/edgar/search/#",
    "http://grconnect.com/tox100/ry2018/",
    "https://grconnect.com/green100/ry2018/",
    "https://www.asyousow.org/report/clean200-2019-q1",
    "http://www.politicalaccountability.net/cpa-zicklin-index/",
    "https://forcetheissue.org/#",
    "https://worthrises.org/theprisonindustry2020#block-5bc4c1200a7eaff4fcef",
    "https://www.asyousow.org/report-page/waste-and-opportunity-2020-searching-corporate-leadership",
    "https://www.goodjobsfirst.org/violation-tracker",
    "https://aflcio.org/executive-paywatch/company-pay-ratios",
  ]);
  const [tableScoreImage, setTableScoreImage] = useState("");
  const [tableScoreImage2, setTableScoreImage2] = useState("");
  const [tableScoreImage3, setTableScoreImage3] = useState("");
  const [source1, setSource1] = useState("");
  const [source2, setSource2] = useState("");
  const [source3, setSource3] = useState("");
  const [fROB, setFROB] = useState("");
  const [prisonHarmScore, setPrisonHarmScore] = useState("");
  const [darkMoneyCPAScore, setDarkMoneyCPAScore] = useState("");
  const [presenceClean200Score, setPresenceClean200Score] = useState("");
  const [payRatioScore, setPayRatioScore] = useState("");
  const [airPollutantScore, setAirPollutantScore] = useState("");
  const [scoreNumber, setScoreNumber] = useState("");
  const [scoreNumber2, setScoreNumber2] = useState("");
  const [scoreNumber3, setScoreNumber3] = useState("");
  const [immDetLabor, setImmDetLabor] = useState("");
  const [invPrisLabor, setInvPrisLabor] = useState("");
  const [invPrisLaborScore, setInvPrisLaborScore] = useState("");
  const [ceoPayRatioScore, setCEOPayRatioScore] = useState("");
  const [gHPresence, setGHPresence] = useState("");
  const [greenHousePercentage, setGreenHousePercentage] = useState("");
  const [meetsRequirementsWording, setMeetsRequirementsWording] = useState("");
  const [meetsRequirementsWording2, setMeetsRequirementsWording2] =
    useState("");
  const [meetsRequirementsWording3, setMeetsRequirementsWording3] =
    useState("");
  const [causeSlug, setCauseSlug] = useState("");

  const initialContext = {
    companies,
    setCompanies,
    clickedCompany,
    setClickedCompany,
    companyData,
    setCompanyData,
    cardData,
    setCardData,
    prisonData,
    setPrisonData,
    darkMoneyData,
    setDarkMoneyData,
    climateChangeData,
    setClimateChangeData,
    modalVisible,
    setModalVisible,
    humanCapitalData,
    setHumanCapitalData,
    racialJusticeData,
    setRacialJusticeData,
    shareholderData,
    setShareholderData,
    menuState,
    setMenuState,
    menuWording,
    setMenuWording,
    tableHead,
    setTableHead,
    tableData,
    setTableData,
    modalText,
    setModalText,
    dataTable,
    setDataTable,
    dataTable2,
    setDataTable2,
    dataTable3,
    setDataTable3,
    harmScore,
    setHarmScore,
    immScore,
    setImmScore,
    prisonLaborScore,
    setPrisonLaborScore,
    goodJobScore,
    setGoodJobScore,
    greenHouseScore,
    setGreenHouseScore,
    clean200Score,
    setClean200Score,
    ceoPayRatio,
    setCeoPayRatio,
    sHScore,
    setSHScore,
    greenHouseEmissions,
    setGreenHouseEmissions,
    toxicEmissions,
    setToxicEmissions,
    dateSourced,
    setDateSourced,
    modalImage,
    setModalImage,
    modalBGStyle,
    setModalBGStyle,
    modalScoreImage,
    setModalScoreImage,
    sources,
    setSources,
    tableScoreImage,
    setTableScoreImage,
    tableScoreImage2,
    setTableScoreImage2,
    tableScoreImage3,
    setTableScoreImage3,
    source1,
    setSource1,
    source2,
    setSource2,
    source3,
    setSource3,
    modalCloseColor,
    setModalCloseColor,
    menuColor,
    setMenuColor,
    menuIcon,
    setMenuIcon,
    menuBackground,
    setMenuBackground,
    menuWordingStyle,
    setMenuWordingStyle,
    fROB,
    setFROB,
    scoreNumber,
    setScoreNumber,
    prisonHarmScore,
    setPrisonHarmScore,
    darkMoneyCPAScore,
    setDarkMoneyCPAScore,
    presenceClean200Score,
    setPresenceClean200Score,
    payRatioScore,
    setPayRatioScore,
    airPollutantScore,
    setAirPollutantScore,
    greenHousePercentage,
    setGreenHousePercentage,
    scoreNumber2,
    setScoreNumber2,
    scoreNumber3,
    setScoreNumber3,
    immDetLabor,
    setImmDetLabor,
    invPrisLabor,
    setInvPrisLabor,
    gHPresence,
    setGHPresence,
    meetsRequirementsWording,
    setMeetsRequirementsWording,
    meetsRequirementsWording2,
    setMeetsRequirementsWording2,
    meetsRequirementsWording3,
    setMeetsRequirementsWording3,
    invPrisLaborScore,
    setInvPrisLaborScore,
    ceoPayRatioScore,
    setCEOPayRatioScore,
    causeSlug,
    setCauseSlug,
  };
  const setMenu = () => {
    const asyncMenuState = async () => {
      const response = await setMenuState(true);
    };
    asyncMenuState();
    if (menuState) {
      setMenuWording("MENU");
      setMenuState(false);
      setMenuColor("#09ccc7");
      setMenuIcon("bars");
      setMenuBackground("white");
      setMenuWordingStyle(styles.menuOpenText);
    } else {
      setMenuWording("CLOSE");
      setMenuIcon("close");
      setMenuColor("white");
      setMenuBackground("#09ccc7");
      setMenuWordingStyle(styles.menuCloseText);
    }
    console.log(menuState);
  };

  useEffect(()=>{
    if (gtmId) {
      TagManager.initialize({ gtmId });
    }
  },[])

  const WindowSize = () => {
    const window = useWindowDimensions();
    if(window.width < 600){
        return (
            // <View style={styles.menuButton}>
            <CustomButton />
            //  </View>
        );
    }else{
      return (
        // <View style={styles.menuButton2}>
        <CustomTextButton />
        // </View>
      );
    }
  };
  const CustomTextButton = () => {
    return (
      <Icon.Button
        name={menuIcon}
        color={menuColor}
        style={styles.iconButtonStyle}
        backgroundColor={menuBackground}
        onPress={() => setMenu()}
      >
        <Text style={menuWordingStyle}>{menuWording}</Text>
      </Icon.Button>
    );
  };
  const CustomButton = () => {
    return (
      <Icon.Button
        name={menuIcon}
        color={menuColor}
        style={styles.iconNoWordButtonStyle}
        backgroundColor={menuBackground}
        onPress={() => setMenu()}
      >
        {/* <Text style={menuWordingStyle}>{menuWording}</Text> */}
      </Icon.Button>
    );
  };
  // useEffect(() => {
  //   setMenu();
  // },[menuState]);
  const BackHomeButton = () => {
    return (
      <Icon.Button
        name="arrow-left"
        backgroundColor="transparent"
        style={styles.backHomeButton}
      >
        <Text style={styles.backHomeText}>BACK HOME</Text>
      </Icon.Button>
    );
  };
  if (!fontsLoaded) {
    return <AppLoading />;
  } else {
    return (
      <AppProvider value={initialContext}>
        <Router>
          <View style={styles.container}>
            <View style={styles.menuButton}>
             
              <WindowSize />
            </View>
            <StatusBar style="auto" />
            <Switch>
              <Route exact path="/Home">
                {menuState ? <Menu /> : <Home />}
              </Route>
              <Route exact path="/">
                {menuState ? <Menu /> : <Home />}
              </Route>
              <Route exact path="/womenempowerment">
                {menuState ? <Menu /> : <WomenEmpowerment />}
              </Route>
              <Route exact path="/prisonprofiteering">
                {menuState ? <Menu /> : <PrisonProfiteering />}
              </Route>
              <Route exact path="/climatechange">
                {menuState ? <Menu /> : <ClimateChange />}
              </Route>
              <Route exact path="/racialjustice">
                {menuState ? <Menu /> : <RacialJustice />}
              </Route>
              <Route exact path="/darkmoney">
                {menuState ? <Menu /> : <DarkMoney />}
              </Route>
              <Route exact path="/humancapital">
                {menuState ? <Menu /> : <PeopleOverProfits />}
              </Route>
              <Route path="/company">
                {menuWording === "CLOSE" ? <Menu /> : <CompanyDetails />}
              </Route>
              <Route path="/about">{menuState ? <Menu /> : <AboutUs />}</Route>
              <Route path="/FAQ">{menuState ? <Menu /> : <FAQ />}</Route>
              <Route path="/Contact">
                {menuState ? <Menu /> : <ContactPage />}
              </Route>
              <Route path="/T&C's">
                {menuState ? <Menu /> : <TermsAndConditions />}
              </Route>
              <Route exact path="/menu" component={Menu} />
              <Route path="/ForAdvisors">{menuState ? <Menu /> : <ForAdvisors />}</Route>
              /*<Route
                path="/ForAdvisors"
                component={() => {
                  window.location.href = "https://www.yourstake.org/";
                  return null;
                }}
              />*/
            </Switch>
          </View>
        </Router>
      </AppProvider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    padding: 20,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url(${HomeBG})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
  },
  menuButton: {
    alignSelf: "flex-end",
    position: "absolute",
    top: "1em",
    right: "1em",
    zIndex: 2,
    width: "clamp(85px, 10vw, 145px)",
  },
  menuButton2: {
    alignSelf: "flex-end",
    position: "absolute",
    top: "1em",
    right: "1em",
    zIndex: 2,
    width: "clamp(45px, 5vw, 75px)",
  },
  iconButtonStyle: {
    padding: 15,
    display: "flex",
    flexDirection: "row",
    width: "clamp(85px, 10vw, 145px)",
    justifyContent: "space-around",
    fontSize: "clamp(15px, 1vw, 20px)",
  },
  iconNoWordButtonStyle: {
    padding: 15,
    display: "flex",
    flexDirection: "row",
    width: "clamp(85px, 10vw, 145px)",
    justifyContent: "space-around",
    fontSize: "clamp(15px, 1vw, 20px)",
  },
  header: {
    fontSize: 20,
  },
  buttonStyle: {
    alignItems: "center",
    backgroundColor: "#DDDDDD",
    width: "100%",
    marginTop: 16,
  },
  menuCloseText: {
    alignSelf: "center",
    color: "white",
    fontSize: "clamp(10px, 1vw, 18px)",
    fontFamily: "Gilroy-Bold",
    letterSpacing: 1,
    textAlign: "center",
  },
  menuOpenText: {
    color: "#09ccc7",
    alignSelf: "center",
    fontFamily: "Gilroy-Bold",
    letterSpacing: 1,
    fontSize: "clamp(10px, 1vw, 18px)",
    textAlign: "center",
  },
  backHomeText: {
    alignSelf: "center",
    fontSize: 18,
    color: "white",
    fontFamily: "Gilroy-Bold",
    letterSpacing: 1,
    textAlign: "center",
  },
  backHomeButton: {
    color: "white",
    borderWidth: 2,
    borderColor: "white",
    padding: "1em 3.2em",
  },
});
