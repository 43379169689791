import React, { useContext, useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
} from "react-native";
import {
    BrowserRouter as Router,
    Link,
  } from "react-router-dom";
import Icon from "react-native-vector-icons/FontAwesome";
const BackHomeButton = () => {
    return (
        <Link to="/Home" style={{ textDecoration: 'none' }}>
<Icon.Button
        name="arrow-left"
        backgroundColor="transparent"
        style={styles.backHomeButton}
      >
        <Text style={styles.backHomeText}>BACK HOME</Text>
      </Icon.Button>
        </Link>
      
    );
  };
  const styles = StyleSheet.create({
    backHomeText: {
      alignSelf: "center",
      fontSize: 15,
      color: "white",
      fontFamily: "Gilroy-Bold",
      letterSpacing: 1,
      textAlign: "center",
    },
    backHomeButton: {
      color: "white",
      borderWidth: 2,
      borderColor: "white",
      height:40,
      width: 180,
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 60,
    },
  });
  
  export default BackHomeButton;