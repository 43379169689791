import React, { useContext, useState, useEffect } from "react";
import Helmet from 'react-helmet';
import axios from "axios";
import { View, Text, Modal, StyleSheet, Pressable, Image } from "react-native";
import { BrowserRouter as Router, Link } from "react-router-dom";
import AppContext from "./AppContext";
import Logo from "./Logo";
import POPLogo from "../assets/people-over-profits-10.19.png";
import Footer from "./Footer";
import BackHomeButton from "./BackHomeButton";
// import Home from './Home'

const FAQ = () => {
  const context = useContext(AppContext);
  const B = (props) => <Text style={{fontWeight: 'bold'}}>{props.children}</Text>
    const [seoData, setSeo] = useState([]);
    
    const getSeoData = async () => {
      try {
        const seo = await axios.get(
          `${ process.env.APP_MANIFEST.Local_URL}seo?pageName=FAQ`
        );
        setSeo(
          seo?.data[0]
        );
      } catch (error) {
        // handle error
        alert(error);
      }
    }
    
    useEffect(() => {
      if (seoData.length === 0) {
          console.log("Getting SEO Data");
        getSeoData();
      }
    });
  return (
    <><Helmet>
      <meta charset="utf-8" />
      <meta httpequiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1.00001, viewport-fit=cover" />
          <meta name="description" content={seoData.metaDesc} />
          <meta name="keywords" content={seoData.metaKeywords} />
      <title>{seoData.pageTitle}</title>
    </Helmet>
    <View style={styles.container}>
        <Logo
          style={{
            resizeMode: "contain",
            width: 250,
            height: 65,
            placeSelf: "center",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "2em",
          }} />
        <View style={styles.innerContainer}>
          <BackHomeButton />
          <View style={styles.innerPageContainer}>
            <View style={styles.paragraph}>
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: "4vmax",
                  fontFamily: "Gilroy-Bold",
                }}
              >
                FAQ
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: 17,
                  lineHeight: "1.7em",
                  marginTop: "1.7em",
                  fontFamily: "Typekit",
                  paddingBottom: "1.7em",
                  fontWeight: "bold",
                }}
              >
                {`How do you score companies?`}
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: 17,
                  lineHeight: "1.7em",
                  marginTop: "1.7em",
                  fontFamily: "Typekit",
                  borderBottom: "1px solid white",
                  paddingBottom: "1.7em",
                }}
              >{`We value transparency. Here is our methodology within each category:`}</Text>
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: 17,
                  lineHeight: "1.7em",
                  marginTop: "1.7em",
                  fontFamily: "Typekit",
                  fontWeight: "bold",
                }}
              >{`Women on Boards`}</Text>
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: 17,
                  lineHeight: "1.7em",
                  // marginTop: "1.7em",
                  fontFamily: "Typekit",
                }}
              >
                {`While we’d love to see full gender parity on boards, we have a long way to go, and only a handful of companies have accomplished that at this point. Using data from`}<Link to={{ pathname: "https://5050wob.com/directory/" }} target="_blank" style={{ textDecoration: "none", flex: "31%" }}><Text style={styles.linkStyle}> 50/50 Women on boards</Text></Link>{`, we have a requirement of greater than 30% representation by women on the board of directors. Corporations that have between 20% and 30% representation are a “caution” yellow and corporations that have less than 20% representation are “red.” We will be adding Good Jobs First Violation tracker for gender discrimination, including pregnancy, in employment and/or consumer practices in the near future, so keep checking back!`}
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: 17,
                  lineHeight: "1.7em",
                  marginTop: "1.7em",
                  fontFamily: "Typekit",
                  fontWeight: "bold",
                }}
              >{`Prison Profiteering`}</Text>
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: 17,
                  lineHeight: "1.7em",
                  // marginTop: "1.7em",
                  fontFamily: "Typekit",
                }}
              >
                {`We use`}<Link to={{ pathname: "https://worthrises.org/theprisonindustry2020" }} target="_blank" style={{ textDecoration: "none", flex: "31%" }}><Text style={styles.linkStyle}> Worth Rises</Text></Link> {`data to score corporations on prison profiteering. We require a harm score of less than 10. We require no involvement with immigration detention or prison labor. If a corporation fails to meet any of these three requirements, they are “red” for the entire prison profiteering category. 
`}
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: 17,
                  lineHeight: "1.7em",
                  marginTop: "1.7em",
                  fontFamily: "Typekit",
                  fontWeight: "bold",
                }}
              >{`Dark Money`}</Text>
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: 17,
                  lineHeight: "1.7em",
                  // marginTop: "1.7em",
                  fontFamily: "Typekit",
                }}
              >
                {`We use the`}<Link to={{ pathname: "https://www.politicalaccountability.net/wp-content/uploads/2021/11/2021-CPA-Zicklin-Index.pdf" }} target="_blank" style={{ textDecoration: "none", flex: "31%" }}><Text style={styles.linkStyle}> CPA Zicklin Index</Text></Link> {`to score corporations on Dark Money involvement. We require a score of greater than 90 to pass. A score between 10 and 90 is “caution” yellow.  A score below 10 is red.`}
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: 17,
                  lineHeight: "1.7em",
                  marginTop: "1.7em",
                  fontFamily: "Typekit",
                  fontWeight: "bold",
                }}
              >{`Climate Change`}</Text>
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: 17,
                  lineHeight: "1.7em",
                  // marginTop: "1.7em",
                  fontFamily: "Typekit",
                }}
              >
                {`We As You Sow’s`}<Link to={{ pathname: "https://www.asyousow.org/report-page/2021-clean200" }} target="_blank" style={{ textDecoration: "none", flex: "31%" }}><Text style={styles.linkStyle}> Clean200</Text></Link> {`data set, along with PERI institute’s`}<Link to={{ pathname: "https://peri.umass.edu/greenhouse-100-polluters-index-current" }} target="_blank" style={{ textDecoration: "none", flex: "31%" }}><Text style={styles.linkStyle}> Greenhouse 100</Text></Link>{`, and`}<Link to={{ pathname: "https://www.goodjobsfirst.org/violation-tracker" }} target="_blank" style={{ textDecoration: "none", flex: "31%" }}><Text style={styles.linkStyle}> GoodJobs First Violation Tracker</Text></Link> {`to score climate change. Climate 200 is a positive indicator for a corporation, so inclusion in this list is required for green, while exclusion is “caution” yellow. The Greenhouse 100 lists the largest greenhouse emitters in the US, so inclusion on that list is automatically a red. For GoodJobs First, we totaled the environmental violation fines for each corporation. Any corporation with fines greater than their sector’s average scores red. A red in any sub-category results in a red for the overall category. `}
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: 17,
                  lineHeight: "1.7em",
                  marginTop: "1.7em",
                  fontFamily: "Typekit",
                  fontWeight: "bold",
                }}
              >{`People over Profits`}</Text>
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: 17,
                  lineHeight: "1.7em",
                  // marginTop: "1.7em",
                  fontFamily: "Typekit",
                }}
              >
                {`We use CEO Pay Ratio data from`}<Link to={{ pathname: "https://aflcio.org/executive-paywatch/company-pay-ratios" }} target="_blank" style={{ textDecoration: "none", flex: "31%" }}><Text style={styles.linkStyle}> AFLCIO</Text></Link> {`along with corporate policy on requiring arbitration for claims of sexual harassment. CEO Pay Ratio requires a ratio of less than 150:1 for positive marks. Between 150:1 and 300:1 is “caution” yellow, and above 300:1 is red. We require corporations to NOT require arbitration for sexual harassment claims. A red in either of these categories means an overall red for the category.
Please note: ForceTheIssue is no longer a functioning website, and the dataset is no longer publicly available. We’ve reached out to them, but we will be replacing this metric with Good Jobs First Violation Tracker data on employment related offenses as soon as possible, for transparency.`}
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: 17,
                  lineHeight: "1.7em",
                  marginTop: "1.7em",
                  fontFamily: "Typekit",
                  fontWeight: "bold",
                }}
              >{`Racial Justice`}</Text>
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: 17,
                  lineHeight: "1.7em",
                  // marginTop: "1.7em",
                  fontFamily: "Typekit",
                }}
              >
                {`Environmental racism is our primary focus, as it is a measurable, transparent datapoint to focus on. Minorities make up 39% of our country. Corporations have a long history of locating their most toxic facilities in communities of color. We use PERI Institute’s data for`}<Link to={{ pathname: "https://peri.umass.edu/toxic-100-air-polluters-index-current" }} target="_blank" style={{ textDecoration: "none", flex: "31%" }}><Text style={styles.linkStyle}> Toxic </Text></Link>{`and`}<Link to={{ pathname: "https://peri.umass.edu/greenhouse-100-polluters-index-current" }} target="_blank" style={{ textDecoration: "none", flex: "31%" }}><Text style={styles.linkStyle}> Greenhouse</Text></Link> {`gas emitting companies. Our requirement is that minorities bear less than 39% of a corporation’s toxic health risk, and that a corporation has less than 39% of their greenhouse gas emissions in minorities communities. A failure to meet either of these requirements will result in a failure for the Racial Justice category. 
We will be adding a new category for`}<Link to={{ pathname: "https://www.goodjobsfirst.org/violation-tracker" }} target="_blank" style={{ textDecoration: "none", flex: "31%" }}><Text style={styles.linkStyle}> Good Jobs first</Text></Link> {`racial discrimination violation tracker data in the near future, so stay tuned for that. It will include employment based, and consumer based racial discrimination.`}
              </Text>
            </View>
          </View>
        </View>
        <Footer />
      </View></>
  );
};
const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  container: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    // backgroundImage: `url(${InteriorBG})`
  },
  innerContainer: {
    maxWidth: 1200,
    width: "100%",
    margin: "0 auto",
  },
  innerPageContainer: {
    display: "flex",
    width: "clamp(340px, 100%, 1200px)",
    marginLeft: "auto",
    marginRight: "auto",
    flexDirection: "row-reverse",
    justifyContent: "space-around",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  paragraph: {
    width: "100%",
  },
  linkStyle:{
        textAlign: "left",
        color: "#FFFFFF",
        fontSize: 17,
        lineHeight: "1.7em",
        marginTop: "1.7em",
        fontFamily: "Typekit",
        fontWeight: "bold",
  }
});
export default FAQ;
