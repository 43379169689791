import React, { useContext, useState, useEffect } from "react";
import Helmet from 'react-helmet';
import { View, Text, Modal, StyleSheet, Pressable, Image } from "react-native";
import { BrowserRouter as Router, Link } from "react-router-dom";
import AppContext from "./AppContext";
import Logo from "./Logo";
import axios from "axios";
import DMLogo from "../assets/dark-money-10.19.png";
import Footer from "./Footer";
import BackHomeButton from "./BackHomeButton";
import DMInfo from "../assets/dark-money-infographic.png";
import Infographic from "./Infographics";
import Sharingoption from './SharignOptions'
// import Home from './Home'

const DarkMoney = () => {
  const [seoData, setSeo] = useState([]);
  const getSeoData = async () => {
    try {
      const seo = await axios.get(
        `${ process.env.APP_MANIFEST.Local_URL}seo?pageName=DarkMoney`
      );
      setSeo(
        seo?.data[0]
      );
    } catch (error) {
      // handle error
      alert(error);
    }
  }
  useEffect(() => {
    if (seoData.length === 0) {
      getSeoData();
    }
  });
  const context = useContext(AppContext);
  return (
    <>
    <Helmet>
      <meta charset="utf-8" />
      <meta httpequiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1.00001, viewport-fit=cover" />
          <meta name="description" content={seoData.metaDesc} />
          <meta name="keywords" content={seoData.metaKeywords} />
      <title>{seoData.pageTitle}</title>
    </Helmet><View style={styles.container}>
        <Logo
          style={{
            resizeMode: "contain",
            width: 250,
            height: 65,
            placeSelf: "center",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "2em",
          }} />
        <View style={styles.innerContainer}>
          <BackHomeButton />
          <View style={styles.innerPageContainer}>
            <Sharingoption />
            <Image
              source={DMLogo}
              style={{
                width: "clamp(320px, 400px, 24vw)",
                height: "clamp(320px, 400px, 23vw)",
              }} />
            <View style={styles.paragraph}>
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: "4vmax",
                  fontFamily: "Gilroy-Bold",
                }}
              >
                Dark Money
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: 24,
                  lineHeight: "1.7em",
                  marginTop: "1.7em",
                  fontFamily: "Typekit",
                  paddingBottom: "1.7em",
                  borderBottom: "1px solid white",
                }}
              >
                {`“Dark Money” is spending meant to influence political outcomes where the source of the money is not disclosed; usually via nonprofit organizations who are not required to disclose their donors.
`}
                <Link
                  to={{ pathname: "https://www.investopedia.com/" }}
                  target="_blank"
                  style={{ textDecoration: "none", flex: "31%" }}
                >
                  <Text style={styles.linkStyle}>www.investopedia.com</Text>
                </Link>
              </Text>
              <Infographic
                style={{
                  resizeMode: "contain",
                  maxWidth: 800,
                  width: "100%",
                  height: 600,
                  placeSelf: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "2em",
                }}
                source={DMInfo} />
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: 24,
                  lineHeight: "1.7em",
                  marginTop: "1.7em",
                  fontFamily: "Typekit",
                }}
              >
                {`Dark Money is on the rise with over $300M spent on the 2012 presidential election vs. $5.9M in 2004.

It is reported Dark Money spending in the first year of the 2016 presidential election cycle was 10x more than it was at that point in 2012.
`}
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: 24,
                  lineHeight: "1.7em",
                  marginTop: "1.7em",
                  fontFamily: "Typekit",
                }}
              >
                {`Dark Money isn’t a partisan issue as dark money supports all parties. There are many politically active nonprofits in existence who can accept unlimited donations from corporations, unions and individuals, while not having to make this information public.  Fred Wertheimer, the founder and president of Democracy 21, for one, has said that “history makes clear that unlimited contributions and secret money are a formula for corruption.”
`}
              </Text>
            </View>
          </View>
        </View>
        <Footer />
      </View></>
  );
};
const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  container: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    // backgroundImage: `url(${InteriorBG})`
  },
  innerContainer: {
    maxWidth: 1200,
    width: "100%",
    margin: "0 auto",
  },
  innerPageContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row-reverse",
    // justifyContent: "space-around",
    justifyContent:'space-between',
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  paragraph: {
    width: "clamp(65%, 100%, 780px)",
  },
  linkStyle: {
    textAlign: "left",
    color: "#FFFFFF",
    fontSize: 24,
    lineHeight: "1.7em",
    marginTop: "1.7em",
    fontFamily: "Typekit",
    fontWeight: "bold",
    textDecorationLine: "none"
  },
});
export default DarkMoney;
