import React, { useContext } from "react";
import { View, Text, Image, StyleSheet, Pressable } from "react-native";
//import arrowDown from '../assets/arrows-down.png';
import { CardViewWithImage, CardView } from "react-native-simple-card-view";
import Menu from "./Menu";
import Navi from "./shared/Navi";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useLocation,
  withRouter,
  Redirect,
  NavLink,
} from "react-router-dom";
import Logo from "./Logo";
import UnderConstruction from "./UnderConstruction";
import contactCorner1 from "../assets/contact-corner-1.png";
import contactCorner2 from "../assets/contact-corner-2.png";
import AppContext from "./AppContext";
import ContactCorner from "../assets/contact-corner-1.png";
import ContactCornerTwo from "../assets/contact-corner-2.png";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon
} from "react-share";

const miniCardStyle = {
  shadowColor: "#000000",
  shadowOffsetWidth: 2,
  shadowOffsetHeight: 2,
  shadowOpacity: 0.1,
  shadowRadius: 5,
  bgColor: "#09CCC7",
  padding: 5,
  alignItems: "center",
  margin: 5,
  height: 145,
  borderRadius: 3,
  elevation: 3,
  // color: '#FFFFFF'
};
const Footer = () => {
  const context = useContext(AppContext);
  return (
    <Router>
      <View style={styles.container2}>
        <View style={styles.footerInfo}>
          <Logo
            style={{
              resizeMode: "contain",
              width: 250,
              height: 65,
              placeSelf: "flex-start",
              marginBottom: "1em",
            }}
          />
          {/* <Text
            style={{
              textAlign: "left",
              color: "#FFFFFF",
              fontSize: 17,
              fontFamily: "Typekit",
              lineHeight: "1.5em",
              marginBottom: 50,
            }}
          >This website is proudly powered by 100% renewable electricity from Canada</Text> */}
          <Link
            to={{
              pathname: "https://mediaura.com/",
            }}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <Text
              style={{
                textAlign: "left",
                color: "#929499",
                fontSize: 13,
                marginBottom: 10,
                fontFamily: "Typekit",
              }}
            >
              Powered by: Mediaura
            </Text>
          </Link>
          <Link
            to="/T&C's"
            style={{ textDecoration: "none" }}
          >
            <Text
              style={{
                textAlign: "left",
                color: "#636770",
                fontSize: 13,
                fontFamily: "Typekit",
              }}
              onPress={() => {
                console.log("clicked");
              }}
            >
              Privacy Policy | Terms &amp; Conditions
            </Text>
          </Link>
          {/*}          <FacebookShareButton
            url={"www.Lightmoney.com"}
            image="www.lightmoney.com/static/media/logo.a06ec32c.png">
            <FacebookIcon size={32} round={true}/>
          </FacebookShareButton>
          <TwitterShareButton
            url={"Lightmoney.com"}
            quote={"Check out lightmoney.com"}>
            <TwitterIcon size={32} round={true}/>
          </TwitterShareButton>
          <LinkedinShareButton
            url={"Lightmoney.com"}
            quote={"Check out lightmoney.com"}>
            <LinkedinIcon size={32} round={true}/>
            </LinkedinShareButton>*/}
          {/*<Text
              style={{
                textAlign: "left",
                color: "#929499",
                fontSize: 13,
                fontFamily: "Typekit",
                paddingTop: 10,
              }}
              onPress={() => {
                console.log("clicked");
              }}
            >
            This website is proudly focused on efficiency and continuous innovation as we continue on our path to powered by 100% renewable energy.
           </Text>*/}
        </View>
        <View style={styles.footerMenu}>
          <Text style={styles.footerSectionTitle}>Menu</Text>
          <View
            style={{
              gridTemplateColumns: "35% auto",
              display: "grid",
              gap: 15,
            }}
          >
            <Link
              to="/about"
              style={{ textDecoration: "none" }}
            >
              <Text
                style={styles.navItem}
                onPress={() => {
                  console.log("clicked");
                }}
              >
                About
              </Text>
            </Link>
           <Link to="/ForAdvisors" style={{ textDecoration: "none" }}>
            <Text
              onPress={() => {
                console.log("clicked");
              }}
              style={styles.navItem}
            >
              For Advisors
            </Text>
          </Link>
          {/*<Link
              to="/ForAdvisors"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Text style={styles.navItem}>For Advisors</Text>
            </Link>*/}
            <Link to="/FAQ" style={{ textDecoration: "none" }}>
              <Text
                onPress={() => {
                  console.log("clicked");
                }}
                style={styles.navItem}
              >
                FAQ
              </Text>
            </Link>
            <Link to="/Home" style={{ textDecoration: "none" }}>
              <Text
                onPress={() => {
                  console.log("clicked");
                }}
                style={styles.navItem}
              >
                Search Companies
              </Text>
            </Link>
          </View>
        </View>
        <View style={styles.footerContact}>
          {/* <Link to="/Contact" style={{ textDecoration: "none" }}>
          <CardViewWithImage
            onPress={() => {
              console.log("clicked");
            }}
            withBackground={true}
            iconHeight={30}
            iconColor={"#333"}
            width={245}
            title={"Leave Feedback"}
            content={"LoremIpsum"}
            contentFontSize={20}
            titleFontFamily={"Gilroy-Bold"}
            contentFontFamily={"Typekit"}
            titleFontSize={20}
            titleFontFamily={"Typekit"}
            style={miniCardStyle}
          />
          </Link> */}
          <Link to="/Contact" style={{ textDecoration: "none" }}>
            <Pressable
              style={{
                textDecoration: "none",
                backgroundColor: "none",
                width: "100%",
                height: "6vw",
                minHeight: 160,
                minWidth: 235,
                paddingLeft: 15,
                paddingBottom: 15,
                borderRadius: 10,
                // outlineColor: "#09CCC7",
                // outlineStyle: "solid",
                // outlineWidth: 1,
                borderColor: "#09CCC7",
                borderStyle: "solid",
                borderWidth: 1,
                margin: "5px 5px 5px 0",
              }}
            >
              <CardView
                title={"Get In Touch"}
                titleFontSize={12}
                style={{ padding: 0, borderRadius: 10, }}
              >
                <Image source={contactCorner1} style={styles.coloredCorners} />
                <Text style={styles.cardViewTitle}>Get In Touch</Text>
                {/* <Text style={styles.cardViewDescription}>
                  Lorem Ipsum et dolore magna aliqua
                </Text> */}
              </CardView>
            </Pressable>
          </Link>
          <Link to="/Contact" style={{ textDecoration: "none" }}>
            <Pressable
              style={{
                textDecoration: "none",
                backgroundColor: "#09CCC7",
                width: "100%",
                height: "6vw",
                minHeight: 160,
                minWidth: 235,
                paddingLeft: 15,
                paddingBottom: 15,
                borderRadius: 10,
                margin: "5px 5px 5px 0",
              }}
            >
              <CardView
                title={"Leave Feedback"}
                titleFontSize={12}
                style={{ padding: 0 }}
              >
                <Image source={contactCorner2} style={styles.coloredCorners} />
                <Text style={styles.cardViewTitle}>Leave Feedback</Text>
                {/* <Text style={styles.cardViewDescription}>
                  Lorem Ipsum et dolore magna aliqua
                </Text> */}
              </CardView>
            </Pressable>
          </Link>
          {/* <Link to="/Contact" style={{ textDecoration: "none" }}>
          <CardViewWithImage
            onPress={() => {
              console.log("clicked");
            }}
            iconHeight={30}
            iconColor={"#333"}
            width={245}
            title={"Get In Touch"}
            content={"LoremIpsum"}
            contentFontSize={20}
            titleFontFamily={"Gilroy-Bold"}
            contentFontFamily={"Typekit"}
            titleFontSize={20}
            titleFontFamily={"Typekit"}
            style={miniCardStyle}
          />
          </Link> */}
        </View>
        {/* <Menu/> */}
      </View>
          {/*<Switch>
        <Route
          path="/ForAdvisors"
          component={() => {
            window.location.href = "https://www.yourstake.org/";
            return null;
          }}
        />
            </Switch>*/}
    </Router>
  );
};
const styles = StyleSheet.create({
  container2: {
    width: "100%",
    alignItems: "flex-start",
    textAlign: "left",
    maxWidth: 1200,
    width: "95%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "10em",
    marginBottom: "5em",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    flex: 3,
    gap: "2em 1.5vw",
  },
  ImageBackground: {
    flex: 1,
    resizeMode: "cover",
    width: "100%",
    alignItems: "center",
  },
  cardViewTitle: {
    fontFamily: "Gilroy-Bold",
    fontSize: 22,
    letterSpacing: 1,
    color: "white",
    marginTop: "4.5em",
  },
  cardViewDescription: {
    fontFamily: "Typekit",
    letterSpacing: 0,
    fontSize: 12,
    color: "white",
  },
  navItem: {
    fontFamily: "Typekit",
    color: "white",
    fontSize: "1em",
    letterSpacing: 1,
  },
  footerCard: {
    // padding: 30,
    //flex: 1,
    backgroundColor: "#1A1C22",
    alignItems: "center",
    justifyContent: "center",
    //minHeight: "100vh",
    //width: '50%' // is 50% of container width
  },
  footerInfo: {
    maxWidth: 450,
    minWidth: 320,
   // marginRight: "4em",
    flexBasis: 0,
    width: "100%",
    flexGrow: 1,
    flexShrink: 1,
  },
  footerMenu: {
    flex: "1 1 auto",
    maxWidth: 280,
    marginTop: 25,
    marginBottom: 25,
    width: "100%",
    minWidth: 280,
  },
  footerContact: {
    maxWidth: 250,
    width: "100%",
    minWidth: 250,
    alignItems: "flex-start",
    gap: "1em 0",
  },
  footerSectionTitle: {
    alignSelf: "left",
    fontSize: "1.5em",
    color: "white",
    fontWeight: 800,
    textAlign: "left",
    marginBottom: 40,
    fontFamily: "Gilroy-Bold",
    letterSpacing: 1,
  },
  coloredCorners: {
    width: 85,
    height: 85,
    alignSelf: "flex-end",
    position: "absolute",
    right: 0,
  },
});

export default Footer;
