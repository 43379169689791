import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { View, Text } from "react-native";
var loremIpsum = require("lorem-ipsum-react-native");
import AppContext from "./AppContext";

const AboutCompany = () => {
  const location = useLocation();
  const context = useContext(AppContext);
  const CompanySearchOrNot = () => {
    if (location.pathname.includes("/company")) {
      // console.log(location.pathname);
      return (
        <View style={{ paddingTop: 10, marginBottom: 75 }}>
          <Text
            style={{
              textAlign: "left",
              color: "#FFFFFF",
              lineHeight: "1.2em",
              letterSpacing: 1.5,
              width: "100%",
              fontFamily: "Gilroy-Bold",
              fontSize: "clamp(36px, 3.2vw, 60px)",
            }}
          >
            {!!context.companyData && <Text>{context.companyData}</Text>}
          </Text>
          {/* <Text style={{ textAlign: "left", color: "#FFFFFF", fontSize: 17, lineHeight: "1.7em", marginTop: "1.7em", maxWidth:585, width:"100%", alignSelf: "left", fontFamily: "Typekit" }}>
        {`Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`}
      </Text> */}
        </View>
      );
    } else {
      return (
        <View style={{ paddingTop: 10, marginBottom: "clamp(18px, 4vw, 75px)" }}>
          <Text
            style={{
              textAlign: "center",
              color: "#FFFFFF",
              lineHeight: "1.2em",
              letterSpacing: 1.5,
              maxWidth: 820,
              width: "100%",
              alignSelf: "center",
              fontFamily: "Gilroy-Bold",
              fontSize: "clamp(36px, 3.2vw, 80px)",
            }}
          >{`Shining a Light on Corporate Behavior`}</Text>
          {/* <Text style={{ textAlign: "center", color: "#FFFFFF", fontSize: 17, lineHeight: "1.7em", marginTop: "1.7em", maxWidth:585, width:"100%", alignSelf: "center", fontFamily: "Typekit" }}>
        {`Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`}
      </Text> */}
        </View>
      );
    }
  };

  return <CompanySearchOrNot />;
};

export default AboutCompany;
