import React from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";

const CompanyItem = props => {
  return (
      <TouchableOpacity activeOpacity={0.8} onPress={props.onDelete.bind(this, props.id)} >
    <View style={styles.companyItem} >
      <Text style={{ color: "#FFFFFF" }}>{props.title}</Text>
    </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
    companyItem: {
        padding: 10,
        borderWidth: 1,
      },
});

export default CompanyItem;
