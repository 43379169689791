import React, { useState } from "react";
import { TextInput, View, Button, StyleSheet } from "react-native";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import axios from 'axios';
const CompanyInput = (props) => {
  const [enteredCompany, setEnteredCompany] = useState("");

  const companyInputHandler = (enteredText) => {
    setEnteredCompany(enteredText);
  };
  const getDataUsingAsyncAwaitGetCall = async () => {
    try {
      const response = await axios.get(
        // 'https://lmback.mediauradev.com/databasecompanies',
        `${process.env.APP_MANIFEST.lmback_URL}databasecompanies`
      );
      alert(JSON.stringify(response.data));
    } catch (error) {
      // handle error
      alert(error.message);
    }
  };
  return (
    <View style={styles.search}>
      <TextInput
        placeholder="Search Companies"
        style={styles.textInput}
        onChangeText={companyInputHandler}
        value={enteredCompany}
      />
      <Link to="/company">
      <Button
        title="Search"
        onPress={
          // () => setOutputText("The text Changed")
          props.onAddCompany.bind(this, enteredCompany)
        }
        style={styles.button4}
      />
      </Link>
    </View>
  );
};

const styles = StyleSheet.create({
  search: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textInput: {
    width: 600,
    height: 50,
    borderBottomColor: "black",
    borderWidth: 1,
    backgroundColor: "#E8E8E8",
  },
  button4:{
    backgroundColor:"#90A4AE"
  }
});

export default CompanyInput;
