import React, { useContext, useState, useEffect } from "react";
import Helmet from 'react-helmet';
import axios from "axios";
import { View, Text, Modal, StyleSheet, Pressable, Image } from "react-native";
import { BrowserRouter as Router, Link } from "react-router-dom";
import AppContext from "./AppContext";
import Logo from "./Logo";
import POPLogo from "../assets/people-over-profits-10.19.png";
import Footer from "./Footer";
import BackHomeButton from "./BackHomeButton";
// import Home from './Home'

const ForAdvisors = () => {
  const context = useContext(AppContext);
    const [seoData, setSeo] = useState([]);
    
    const getSeoData = async () => {
      try {
        const seo = await axios.get(
          `${ process.env.APP_MANIFEST.Local_URL}seo?pageName=Advisors`
        );
        setSeo(
          seo?.data[0]
        );
      } catch (error) {
        // handle error
        alert(error);
      }
    }
    
    useEffect(() => {
      if (seoData.length === 0) {
          console.log("Getting SEO Data");
        getSeoData();
      }
    });
  return (
    <><Helmet>
      <meta charset="utf-8" />
      <meta httpequiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1.00001, viewport-fit=cover" />
          <meta name="description" content={seoData.metaDesc} />
          <meta name="keywords" content={seoData.metaKeywords} />
      <title>{seoData.pageTitle}</title>
    </Helmet><View style={styles.container}>
      <Logo
        style={{
          resizeMode: "contain",
          width: 250,
        height: 65,
        placeSelf: "center",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom:"2em",
        }}
      />
      <View style={styles.innerContainer}>
        <BackHomeButton />
        <View style={styles.innerPageContainer}>
          <View style={styles.paragraph}>
            <Text
              style={{
                textAlign: "left",
                color: "#FFFFFF",
                fontSize: "4vmax",
                fontFamily: "Gilroy-Bold",
              }}
            >
              For Advisors
            </Text>
            <Text
              style={{
                textAlign: "left",
                color: "#FFFFFF",
                fontSize: 17,
                lineHeight: "1.7em",
                marginTop: "1.7em",
                fontFamily: "Typekit",
              }}
            >
          {`LightMoney.com was created to be a user-friendly data source of corporate behaviors to assist those who are looking to "vote with their dollars".  For financial planning purposes, a tool exists allowing you to create custom impact reports based on a personality-style behavioral questionnaire.  This tool, available at`}<Link to={{pathname: "https://www.yourstake.org"}}target="_blank" style={{ textDecoration: "none", flex: "31%" }}><Text style={styles.linkStyle}> www.YourStake.org</Text></Link>{`, also allows you to screen investments based on the custom values of the clients you advise, and build a portfolio truly reflecting those values.`}
            </Text>
          </View>
        </View>
      </View>
      <Footer />
    </View></>
  );
};
const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  container: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    // backgroundImage: `url(${InteriorBG})`
  },
  innerContainer: {
    maxWidth: 1200,
    width: "100%",
    margin: "0 auto",
  },
  innerPageContainer: {
    display: "flex",
    width: "clamp(340px, 100%, 1200px)",
    marginLeft: "auto",
    marginRight: "auto",
    flexDirection: "row-reverse",
    justifyContent: "space-around",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  paragraph: {
    width:"100%",
  },
  linkStyle:{
    textAlign: "left",
    color: "#FFFFFF",
    fontSize: 17,
    lineHeight: "1.7em",
    marginTop: "1.7em",
    fontFamily: "Typekit",
    fontWeight: "bold",
  }
});
export default ForAdvisors;
