import React, { useContext } from "react";
import { View, StyleSheet, Linking } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import AppContext from "./AppContext";

const Sharingoption = () => {
    const context = useContext(AppContext)
    const currentURL = window.location.href;
    // const url = "https://www.benjerry.com/values/issues-we-care-about";
    return (<>
        <View style={styles.modalSharingButton}>
            <View style={{ marginBottom: "10px" }}>
                <Icon.Button
                    name="facebook"
                    backgroundColor="#3b5998"
                    style={{padding: "11px"}}
                    onPress={() =>
                        Linking.openURL(
                            `https://www.facebook.com/sharer/sharer.php?u=${currentURL}`
                        )
                    }
                ></Icon.Button>
            </View>
            <View style={{ marginBottom: "10px" }}>
                <Icon.Button
                    name="linkedin"
                    backgroundColor="#0e76a8"
                    style={{padding: "11px"}}
                    onPress={() =>
                        Linking.openURL(
                            `https://www.linkedin.com/share?url=${currentURL}`
                        )
                    }
                ></Icon.Button>
            </View>
            <View>
                <Icon.Button
                    name="twitter"
                    backgroundColor="#00acee"
                    style={{padding: "11px"}}
                    onPress={() =>
                        Linking.openURL(
                            `https://twitter.com/intent/tweet?screen_name=${currentURL}`
                        )
                    }
                ></Icon.Button>
            </View>
        </View></>)
}


export default Sharingoption

const styles = StyleSheet.create({
    modalTable: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    modalSharingButton: {
        flexDirection: "row",
        justifyContent: "flex-end",
        display: "block",
        marginLeft: "50px",
        width: "35px",
        // marginRight:"-75px"
        position:"fixed",
        right:"0px"
    },
})