import React, { useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  Text,
  View,
  FlatList,
  Image,
  TextInput,
  TouchableOpacity,
  Button,
  Pressable,
} from "react-native";
import Logo from "./Logo";
import AboutCompany from "./AboutCompany";
import Footer from "./Footer";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
} from "react-router-dom";
import { CardViewWithImage, CardView } from "react-native-simple-card-view";
import WELogo from "../assets/women-empowerment.png";
import PPLogo from "../assets/prisoner-profiteering.png";
import DMLogo from "../assets/dark-money-10.19.png";
import CCLogo from "../assets/climate-change.png";
import HCLogo from "../assets/people-over-profits-10.19.png";
import RJLogo from "../assets/racial-justice-09.png";
import WomenEmpowerment from "./WomenEmpowerment";
import arrowDown from "../assets/arrows-down.png";
import axios from "axios";
import AppContext from "./AppContext";
import Menu from "./Menu";
import BackHomeButton from "./BackHomeButton";
const UnderConstruction = () => {
  const context = useContext(AppContext);
  useEffect(() => {
  });
  return (
      <View>
        <View style={styles.container}>
        <BackHomeButton />
          <View style={styles.topSectionWrapper}>
            <Logo style={{ 
        resizeMode: "contain",
        width: "100%",
        height: 90,
      }}/>
            <Text style={{ textAlign: "center", color: "#FFFFFF", fontSize: 60, lineHeight: "1.2em", letterSpacing: 1.5, maxWidth:820, width:"100%", alignSelf: "center", fontFamily: "Gilroy-Bold" }}>This Page Is Under Construction</Text>
          </View>
          <View
            style={{
              maxWidth: 1400,
              width: "95%",
              alignItems:"center",
            }}
          >
          </View>
        </View>
        <View style={styles.footer}>
          <Footer />
        </View>
      </View>
  );
};

export default UnderConstruction;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  topSectionWrapper: {
    height: "100vh",
    maxWidth: 820,
    //gap:50, caused too much space
    width: "95%",
    justifyContent: "center",
  },
  cardBox: {
    padding: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    flex: 3,
    gap: "4.5vw 1.5vw",
  },
  linkBox: {
    flex: "31%",
  },
  headerStyleOne: {
    fontSize: 52,
    fontFamily: "Gilroy-Bold",
    color: "white",
    marginBottom: "0.3em",
  },
  bodyStyle: {
    fontFamily: "Typekit",
    fontSize: 17,
    lineHeight: "1.7em",
    color:"white",
  },
  cardView: {
    width: "unset",
  },
  cardViewImg: {
    width: 250,
    height: 250,
    alignSelf: "center",
  },
  cardViewTitle: {
    alignSelf: "center",
    fontSize: "1.5em",
    color: "white",
    textAlign: "center",
    fontFamily: "Gilroy-Bold",
    letterSpacing: 1.5,
  },
  cardViewDesc: {
    color: "white",
    alignSelf: "center",
    textAlign: "center",
    marginTop: "1em",
    fontSize: 16,
  },
  description: {
    padding: 10,
    textAlign: "center",
  },
  title: {
    textAlign: "center",
  },
  itemStyle: {
    padding: 10,
    color: "black",
    fontSize: "1.3em",
    fontFamily: 'TypeKit2',
    letterSpacing: 1,
  },
});