import React from "react";
import { View, Image, StyleSheet } from "react-native";
import MainLogo from "../assets/logo.png";
import { BrowserRouter as Router, Link } from "react-router-dom";
import AppContext from "./AppContext";
import { useContext } from "react";

export default function Logo({ style }) {
  const context = useContext(AppContext);
  const styles = StyleSheet.create({
    menuCloseText: {
      alignSelf: "center",
      color: "white",
      fontSize: 18,
      fontFamily: "Gilroy-Bold",
      letterSpacing: 1,
      textAlign: "center",
    },
    menuOpenText: {
      color: "#09ccc7",
      alignSelf: "center",
      fontFamily: "Gilroy-Bold",
      letterSpacing: 1,
      fontSize: 18,
      textAlign: "center",
    },
  });
  return (
    <Link
      to="/Home"
      style={{
        textDecoration: "none",
        width: "100%",
        //height: 100,
        resizeMode: "contain",
        //marginBottom: 35,
      }}
      onClick={() => {console.log("clicked");context.setMenuState(false);context.setMenuWording("MENU");
      context.setMenuState(false);
      context.setMenuColor("#09ccc7");
      context.setMenuIcon("bars");
      context.setMenuBackground("white");
      context.setMenuWordingStyle(styles.menuOpenText);}}
    >
      <Image
        onPress={() => {
          console.log("clicked");
        }}
        style={style}
        source={MainLogo}
      />
    </Link>
  );
}
