import React, { useContext, useState, useEffect } from "react";
import Helmet from 'react-helmet';
import {
  View,
  Text,
  Modal,
  StyleSheet,
  Pressable,
  Image,
  TextInput,
  Button,
  Alert,
} from "react-native";
import { BrowserRouter as Router, Link, Redirect, useHistory } from "react-router-dom";
import AppContext from "./AppContext";
import { useForm, Controller } from "react-hook-form";
import Constants from "expo-constants";
import Logo from "./Logo";
import PPLogo from "../assets/prisoner-profiteering.png";
import Footer from "./Footer";
import axios from "axios";
import BackHomeButton from "./BackHomeButton";
const ContactPage = () => {
  const [status, setStatus] = useState("Submit");
  const [seoData, setSeo] = useState([]);
  const context = useContext(AppContext);
  const history = useHistory();
  const {
    register,
    setValue,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      emailAddress: "",
      message: "",
    },
  });
  const validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
  };

  const getSeoData = async () => {
    try {
      const seo = await axios.get(
        `${process.env.APP_MANIFEST.Local_URL}Contact`
      );
      setSeo(
        seo?.data[0]
      );
    } catch (error) {
      // handle error
      alert(error);
    }
  }
  useEffect(() => {
    if (seoData.length === 0) {
      getSeoData();
    }
  });

  const onSubmit = async (data, e) => {
    if (validateEmail(data.emailAddress)) {
    console.log(data.firstName);
    let details = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.emailAddress,
      message: data.message,
    };
    setStatus("Sending...");
    // e.preventDefault();
    try{
      const request = await axios({
        method: "POST",
        // url: "https://backend.lightmoney.com/contact",
        url: `${process.env.APP_MANIFEST.URL}contact`,
        // headers: {
        //   "Content-Type": "application/json:charSet=UTF-8",
        // },
        data: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.emailAddress,
        message: data.message,
        }
      });
      setStatus("Sent");
      alert("Message sent successfully");
      sentMessage();
      reset();
      // console.log(response);
      // let result = await response.json();
      // console.log(result.status);
    // alert(result.status);
    }
    catch (error) {
      alert(error.message);
    }
  }
  else{
    alert("Please enter a valid email address");
  }
  };
  const sentMessage = () => { 
      history.push({
        pathname: `/Home`
      });
  }
  const onChange = (arg) => {
    return {
      value: arg.nativeEvent.text,
    };
  };
  // console.log("errors", errors);
  return (
    <><Helmet>
      <meta charset="utf-8" />
      <meta httpequiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1.00001, viewport-fit=cover" />
      <title>{seoData.pageTitle}</title>
    </Helmet>
    <View style={styles.container}>
        <Logo style={{
          resizeMode: "contain",
          width: 250,
          height: 65,
          placeSelf: "center",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "2em",
        }} />
        <View style={styles.innerContainer}>
          <BackHomeButton />
          <View style={styles.innerPageContainer}>
            <View style={styles.paragraph}>
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: "3.5vmax",
                  fontFamily: "Gilroy-Bold",
                }}
              >
                Contact Us
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: 17,
                  lineHeight: "1.7em",
                  marginTop: "1.7em",
                  fontFamily: "Typekit",
                  paddingBottom: "1.7em",
                  borderBottom: "1px solid white",
                }}
              >
                {`If you have any questions or concerns, please fill out this form and we will get back to you as soon as possible.`}
              </Text>
            </View>
            <View style={styles.paragraph}>
              <Text style={styles.label}>First name</Text>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    style={styles.input}
                    onBlur={onBlur}
                    onChangeText={value => onChange(value)}
                    value={value} />
                )}
                name="firstName"
                rules={{ required: true }} />
              <Text style={styles.label}>Last name</Text>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    style={styles.input}
                    onBlur={onBlur}
                    onChangeText={value => onChange(value)}
                    value={value} />
                )}
                name="lastName"
                rules={{ required: true }} />
              <Text style={styles.label}>Email Address</Text>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    style={styles.input}
                    onBlur={onBlur}
                    onChangeText={value => onChange(value)}
                    value={value} />
                )}
                name="emailAddress"
                textContentType="emailAddress"
                rules={{ required: true }} />
              <Text style={styles.label}>Message</Text>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    style={styles.input}
                    onBlur={onBlur}
                    onChangeText={value => onChange(value)}
                    value={value} />
                )}
                name="message"
                rules={{ required: true }} />
              <View style={styles.submitButton}>
                <Button
                  style={styles.submitText}
                  color
                  title={status}
                  onPress={handleSubmit(onSubmit)} />
              </View>
            </View>
          </View>
        </View>
        <Footer />
      </View></>
  );
};
const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  container: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    // backgroundImage: `url(${InteriorBG})`
  },
  innerContainer: {
    maxWidth: 1200,
    width: "100%",
    margin: "0 auto",
  },
  innerPageContainer: {
    display: "flex",
    width: "clamp(340px, 100%, 65%)",
    marginLeft: "auto",
    marginRight: "auto",
    flexDirection: "row-reverse",
    justifyContent: "space-around",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  paragraph: {
    //maxWidth: "65%",
    width: "100%",
  },
  label: {
    color: 'white',
    margin: 20,
    marginLeft: 0,
  },
  input: {
    backgroundColor: 'white',
    borderColor: 'none',
    height: 40,
    padding: 10,
    borderRadius: 4,
  },
  submitText: {
    alignSelf: "center",
    fontSize: 15,
    color: "white",
    fontFamily: "Gilroy-Bold",
    letterSpacing: 1,
    textAlign: "center",
  },
  submitButton: {
    color: "white",
    borderWidth: 2,
    borderColor: "white",
    height:40,
    width: 180,
    alignItems: 'right',
    justifyContent: 'right',
    marginBottom: 60,
    marginTop: 20,
  },
});
export default ContactPage;
