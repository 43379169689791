import React from "react";
import { View, StyleSheet, Linking, Text } from "react-native";
import AppContext from "./AppContext";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
const pureColors = ['#FF0000', '#FFFF00', '#00FF00'];
const colors = ['#df1b1b', '#63d471'];
class Popovers extends React.Component {
    static contextType = AppContext;
    componentDidMount() {
        const contextData = this.context;
    }

    render() {

        const { text, index, tooltipShow } = this.props;

        return (

            <Tippy placement="top" content={<span >{text}</span>}>
                <span style={{
                    alignItems: 'center',
                    flex: 1
                }} >{ }</span>
            </Tippy>
        );
    }
}

class Progress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            width: 0,
            limit: 0,
        }
    }

    handleHover = (show = false) => () => {
        this.setState({ show });
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                width: `${this.props.value}%`,
                limit: `${this.props.scoreMaxMin}`

            });
        }, 300);
    }

    render() {
        const { value, index } = this.props;

        return (

            <View
                style={[
                    styles.progress, { width: this.state.width, backgroundColor: colors[index], borderRightWidth: this.props.length - 1 === this.props.index ? 0 : 4, borderColor: '#f2d994', overflow: "hidden" },
                ]}
                onMouseEnter={this.handleHover(true)}
                onMouseLeave={this.handleHover(false)}
            >
                {this.state.show && <Popovers text={`${value}%`} index={index} />}
            </View>
        );
    }
}

class ProgressBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            width: 0,
            limit: 0,
        }
    }

    handleHover = (show = false) => () => {
        this.setState({ show });
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                limit: `${this.props.scoreMaxMin}`
            });
        }, 300);
    }
    render() {
        const { data, scoreMaxMin } = this.props;

        return (
            <View style={styles.progressbar}>
                {
                    data.map((item, index) => (
                        <>
                            <Progress length={data.length} key={index} value={item.value} scoreMaxMin={scoreMaxMin} index={index} />
                        </>
                    ))
                }
                {scoreMaxMin > 0 ? <View style={{
                    position: "absolute",
                    top: 0,
                    left: `${scoreMaxMin}%`,
                    width: 3,
                    backgroundColor: "white",
                    height: "100%",
                    zIndex: 9999
                }}
                    onMouseEnter={this.handleHover(true)}
                    onMouseLeave={this.handleHover(false)}

                >
                    {this.state.show && <Popovers text={`${scoreMaxMin}% Current Score`} />}
                </View> : ""}
            </View>
        );
    }
}

class SliderOption extends React.Component {
    static contextType = AppContext;
    componentDidMount() {

        const user = this.context;

    }

    render() {
        const { datas } = this.props;
        let scoreArrray = [];
        let scoreData = 0;
        switch (this.context.modalText) {
            case "Empowering Women":
                scoreData = 30;
                break;
            case "Prison Profiteering":
                scoreData = 10;
                break;
            case "Dark Money":
                scoreData = 90;
                break;
            case "Climate Change":
                scoreData = 50;
                break;
            case "People Over Profits":
                scoreData = 50;
                break;
            case "Racial Justice":
                scoreData = 39;
                break;
            default:
                scoreData = 50;
        }
        let scoreRemainigData = 0;
        let scoreMaxMin = 0;
        if (datas instanceof Array) {
            scoreMaxMin = datas[0]?.replace(/%?/g, "")
            scoreRemainigData = 100 - Number(scoreData)
            scoreArrray.push({ "value": scoreData }, { "value": scoreRemainigData });
        }
        else if (isNaN(datas)) {
            scoreMaxMin = datas?.replace(/%?/g, "");
            scoreRemainigData = 100 - Number(scoreData);
            scoreArrray.push({ "value": scoreData }, { "value": scoreRemainigData });
        } else {
            scoreMaxMin = datas;
            scoreRemainigData = 100 - Number(scoreData);
            scoreArrray.push({ "value": scoreData }, { "value": scoreRemainigData });
        }
        return (
            <View style={styles.container} className="container">
                {scoreMaxMin > 0 ? <ProgressBar data={scoreArrray} scoreMaxMin={scoreMaxMin} /> : ""}
            </View>
        );
    }
}


export default SliderOption

const styles = StyleSheet.create({
    container: {
        minHeight: '50%',
    },
    progress: {
        height: "100%",
        display: "inlineBlock",
        position: "relative",
        transition: `width .5s`,

    },
    progressbar: {
        width: "100%",
        height: " 30px",
        backgroundColor: "white",
        borderRadius: 15,
        position: "relative",
        display: "flex",
        flexWrap: "nowrap",
        flexDirection: "row",
        overflow: "hidden",
        borderWidth: 3,
        borderColor: "#f2d994"
    },
    popover: {
        position: "absolute",
        boxShadow: "0 2px 4px 0 rgba(#c5c5c5, .5)",
        display: "inline-block",
        border: "solid 1px #b3b3b3",
        backgroundColor: "#e6e6e6",
        padding: "10px",
        borderRadius: "5px",
        top: "45px",
        left: 'calc(50% - 28px)',
    },

    color: {
        width: "50px",
        height: "50px",
        display: "inline-block",
        textAlignVertical: "middle",
        marginRight: "5px",
        borderRadius: "3px",
    }

})
