import React, { useState, useEffect, useContext } from "react";
import Helmet from 'react-helmet';
import {
  StyleSheet,
  Text,
  View,
  FlatList,
  Image,
  TextInput,
  TouchableOpacity,
  Button,
  Pressable,
} from "react-native";
import Logo from "./Logo";
import AboutCompany from "./AboutCompany";
import Footer from "./Footer";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
} from "react-router-dom";
import { CardViewWithImage, CardView } from "react-native-simple-card-view";
import WELogo from "../assets/women-empowerment.png";
import PPLogo from "../assets/prisoner-profiteering.png";
import DMLogo from "../assets/dark-money-10.19.png";
import CCLogo from "../assets/climate-change.png";
import HCLogo from "../assets/people-over-profits-10.19.png";
import RJLogo from "../assets/racial-justice-09.png";
import WomenEmpowerment from "./WomenEmpowerment";
import arrowDown from "../assets/arrows-down.png";
import axios from "axios";
import AppContext from "./AppContext";
import Menu from "./Menu";
const Home = () => {
  const context = useContext(AppContext);
  const [companies, setCompanies] = useState([]);
  const [redirect, setredirect] = useState(false);
  const addCompanyHandler = (companyTitle) => {
    setCompanies((currentCompanies) => [
      ...currentCompanies,
      { key: Math.random().toString(), value: companyTitle },
    ]);
  };
  const removeCompanyHandler = (companyId) => {
    setCompanies((currentCompanies) => {
      return currentCompanies.filter((company) => company.id !== companyId);
    });
  };
  const miniCardStyle = {
    shadowColor: "#000000",
    shadowOffsetWidth: 2,
    shadowOffsetHeight: 2,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    bgColor: "#ffffff",
    borderRadius: 3,
    elevation: 3,
    width: "unset",
  };
  const [companyNames, setCompanyNames] = useState([]);
  const [seoData, setSeo] = useState([]);
  const [filteredName, setfilteredName] = useState([]);
  const [search, setsearch] = useState("");
  const [companySearch, setcompanySearch] = useState(false);
  const [clickedCompany, setClickedCompany] = useState("");
  const history = useHistory();

  const getSeoData = async () => {
    try {
      const seo = await axios.get(
        `${ process.env.APP_MANIFEST.URL}seo?pageName=Home`
      );
        console.log(seo?.data[0])
      setSeo(
        seo?.data[0]
      );
    } catch (error) {
      // handle error
      alert(error);
    }
  }

  const getAllCompanyNames = async () => {
    try {
      const response = await axios.get(
        // "https://lmback.mediauradev.com/databasecompanynames"
        // "https://backend.lightmoney.com/databasecompanynames"
         `${ process.env.APP_MANIFEST.URL}databasecompanynames`
        // "http://localhost:3000/databasecompanynames"
      );
      setCompanyNames(
        response.data.map((res, i) => response.data[i]["Company Name"])
      );
      setfilteredName(
        response.data.map((res, i) => response.data[i]["Company Name"])
      );
    } catch (error) {
      // handle error
      alert(error);
    }
  };
  useEffect(() => {
    
    if (companyNames.length === 0) {
      getAllCompanyNames();
      // console.log(context.clickedCompany);
    }
  if (seoData.length === 0) {
    getSeoData();
  }
    
  });
  // const searchFilter = (text) => {
  //   setfilteredName(companyNames);
  //   if (text.length > 1) {
  //     console.log(filteredName);
  //     setcompanySearch(true);
  //     const newData = filteredName.filter((item) => {
  //       const itemData = item ? item.toUpperCase() : "".toUpperCase();
  //       var punctuationless = itemData.replace(/[.,\/#!$%\^&\*;:{}=\-_\'~()]/g,"");
  //       var finalString = punctuationless.replace(/\s/g,"");
  //       const textData = text.toUpperCase();
  //       return finalString.indexOf(textData) > -1;
  //     });
  //     setfilteredName(newData);
  //     setsearch(text);
  //   } else {
  //     setcompanySearch(false);
  //     setsearch(text);
  //     setfilteredName(companyNames);
  //   }
  //   console.log(search);
  // };
  const searchFilter = (text) => {
    setfilteredName(companyNames);
    if (text.length > 1) {
      setcompanySearch(true);
      const newData = companyNames.filter((item) => {
        const itemData = item ? item.toUpperCase() : "".toUpperCase();
        var punctuationless = itemData.replace(
          /[.,\/#!$%\^&\*;:{}=\-_\'~()]/g,
          ""
        );
        var finalString = punctuationless.replace(/\s/g, "");
        const textData = text.toUpperCase();
        return finalString.indexOf(textData) > -1;
      });
      // console.log(newData);
      setfilteredName(newData);
    } else {
      setcompanySearch(false);
    }
    setsearch(text);
  };
  const clickCompany = (item) => {
    context.setClickedCompany(item);
    const companyDetails = async () => {
      try {
        const request = await axios({
          method: "post",
          // url: "https://backend.lightmoney.com/companydetailsearch",
          url: `${ process.env.APP_MANIFEST.URL}companydetailsearch`,
          // "https://lmback.mediauradev.com/companydetailsearch",
          
          data: {
            clickedCompany: item,
          },
        });
        // console.log(item, request.data);
      } catch (error) {
        alert(error.message);
      }
    };

    // console.log(clickedCompany);
    companyDetails();
    setredirect(true);
    // console.log(redirect);
  };
  const willRedirect = (item) => {
      var str = item;
      str = str.replace(/\s+/g, '_');
    history.push({
      pathname: `/company/${str}`,
      state: { clickedCompany: item },
    });
  };
  const ItemView = ({ item }) => {
    return (
      <TouchableOpacity
        onPress={() => {
          clickCompany(item), willRedirect(item);
        }}
      >
        <Text style={styles.itemStyle}>{item.toUpperCase()}</Text>
      </TouchableOpacity>
    );
  };

  const ItemSeparatorView = () => {
    return (
      <View style={{ height: 0.5, width: "100%", backgroundColor: "FFFFFF" }} />
    );
  };
  return (
    <><Router>
      <Helmet>
      <meta charset="utf-8" />
      <meta httpequiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1.00001, viewport-fit=cover" />
          <meta name="description" content={seoData.metaDesc} />
      <title>{seoData.pageTitle}</title>
    </Helmet>
    
        <View></View>
        <View>
          <View style={styles.container}>
            <View style={styles.topSectionWrapper}>
              <Logo style={{
                resizeMode: "contain",
                width: "100%",
                height: 100,
              }} />
              <AboutCompany />
              <View>
                <TextInput
                  style={{
                    height: 60,
                    borderWidth: 1,
                    paddingLeft: 20,
                    margin: 5,
                    borderColor: "#009688",
                    backgroundColor: "white",
                    borderRadius: 5,
                    width: "100%",
                    marginTop: 0,
                    marginBottom: 0,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  value={search}
                  placeholder="search company here"
                  onChangeText={(text) => searchFilter(text)} />
                {filteredName.length <= 10 && companySearch == true ? (
                  <FlatList
                    data={filteredName}
                    keyExtractor={(item, index) => index.toString()}
                    ItemSeparatorComponent={ItemSeparatorView}
                    renderItem={ItemView}
                    maxToRenderPerBatch={5}
                    style={{
                      background: "white",
                      color: "black",
                      borderRadius: 5,
                      marginTop: 4,
                    }} />
                ) : null}
              </View>
              <Image
                style={{
                  width: 65,
                  height: 65,
                  alignItems: "center",
                  placeSelf: "center",
                  justifySelf: "flex-end",
                  position: "absolute",
                  bottom: "6em",
                  zIndex: -2,
                }}
                source={arrowDown} />
            </View>
            <View
              style={{
                maxWidth: 1400,
                width: "95%",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  maxWidth: 770,
                  width: "95%",
                  textAlign: "center",
                }}
              >
                <Text style={styles.headerStyleOne}>
                  Discover our Core Causes
                </Text>
                <Text style={styles.bodyStyle}>
                  Click on the icons below to learn more as you journey through the world of socially responsible corporations.
                </Text>
              </View>
              <View style={styles.cardBox}>
                <Link
                  to="/womenempowerment"
                  style={{ textDecoration: "none", flex: "31%" }}
                >
                  <CardView
                    onPress={() => console.log("Clicked!")}
                    title={"Empowering Women"}
                    titleFontSize={12}
                    style={StyleSheet.flatten(styles.cardView)}
                  >
                    <Image source={WELogo} style={styles.cardViewImg} />
                    <Text style={styles.cardViewTitle}>Empowering Women</Text>
                  </CardView>
                </Link>
                <Link
                  to="/prisonprofiteering"
                  style={{ textDecoration: "none", flex: "31%" }}
                >
                  <CardView
                    onPress={() => console.log("Clicked!")}
                    title={"Prison Profiteering"}
                    titleFontSize={12}
                    style={StyleSheet.flatten(styles.cardView)}
                  >
                    <Image source={PPLogo} style={styles.cardViewImg} />
                    <Text style={styles.cardViewTitle}>Prison Profiteering</Text>
                  </CardView>
                </Link>
                <Link
                  to="/darkmoney"
                  style={{ textDecoration: "none", flex: "31%" }}
                >
                  <CardView
                    onPress={() => console.log("Clicked!")}
                    title={"Dark Money"}
                    titleFontSize={12}
                    style={StyleSheet.flatten(styles.cardView)}
                  >
                    <Image source={DMLogo} style={styles.cardViewImg} />
                    <Text style={styles.cardViewTitle}>Dark Money</Text>
                  </CardView>
                </Link>
                <Link
                  to="/climatechange"
                  style={{ textDecoration: "none", flex: "31%" }}
                >
                  <CardView
                    onPress={() => console.log("Clicked!")}
                    title={"Climate Change"}
                    titleFontSize={12}
                    style={StyleSheet.flatten(styles.cardView)}
                  >
                    <Image source={CCLogo} style={styles.cardViewImg} />
                    <Text style={styles.cardViewTitle}>Climate Change</Text>
                  </CardView>
                </Link>
                <Link
                  to="/humancapital"
                  style={{ textDecoration: "none", flex: "31%" }}
                >
                  <CardView
                    onPress={() => console.log("Clicked!")}
                    title={"Human Capital"}
                    titleFontSize={12}
                    style={StyleSheet.flatten(styles.cardView)}
                  >
                    <Image source={HCLogo} style={styles.cardViewImg} />
                    <Text style={styles.cardViewTitle}>People Over Profits</Text>
                  </CardView>
                </Link>
                <Link
                  to="/racialjustice"
                  style={{ textDecoration: "none", flex: "31%" }}
                >
                  <CardView
                    onPress={() => console.log("Clicked!")}
                    title={"Racial Justice"}
                    titleFontSize={12}
                    style={StyleSheet.flatten(styles.cardView)}
                  >
                    <Image source={RJLogo} style={styles.cardViewImg} />
                    <Text style={styles.cardViewTitle}>Racial Justice</Text>
                  </CardView>
                </Link>
              </View>
            </View>
          </View>
          <View style={styles.footer}>
            <Footer />
          </View>
        </View>
      </Router></>
  );
};

export default Home;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  topSectionWrapper: {
    height: "100vh",
    maxWidth: 820,
    //gap:50, caused too much space
    width: "95%",
    justifyContent: "center",
  },
  cardBox: {
    padding: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    flex: 3,
    gap: "4.5vw 1.5vw",
  },
  linkBox: {
    flex: "31%",
  },
  headerStyleOne: {
    fontFamily: "Gilroy-Bold",
    color: "white",
    marginBottom: "0.3em",
    fontSize: "clamp(37px, 3vw, 55px)",
  },
  bodyStyle: {
    fontFamily: "Typekit",
    fontSize: "clamp(16px, 1vw, 22px)",
    lineHeight: "1.7em",
    color: "white",
  },
  cardView: {
    width: "unset",
  },
  cardViewImg: {
    width: 250,
    height: 250,
    alignSelf: "center",
  },
  cardViewTitle: {
    alignSelf: "center",
    fontSize: "1.5em",
    color: "white",
    textAlign: "center",
    fontFamily: "Gilroy-Bold",
    letterSpacing: 1.5,
  },
  cardViewDesc: {
    color: "white",
    alignSelf: "center",
    textAlign: "center",
    marginTop: "1em",
    fontSize: 16,
  },
  description: {
    padding: 10,
    textAlign: "center",
  },
  title: {
    textAlign: "center",
  },
  itemStyle: {
    padding: 10,
    color: "black",
    fontSize: "1.3em",
    fontFamily: "TypeKit2",
    letterSpacing: 1,
  },
});
