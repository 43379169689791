import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, Pressable, Image } from "react-native";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Logo from "./Logo";
import { CardViewWithImage, CardView } from "react-native-simple-card-view";
import Navi from "./shared/Navi";
import contactCorner1 from "../assets/contact-corner-1.png";
import contactCorner2 from "../assets/contact-corner-2.png";

const Menu = () => {
  const miniCardStyle = {
    shadowColor: "#000000",
    shadowOffsetWidth: 2,
    shadowOffsetHeight: 2,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    bgColor: "#09CCC7",
    padding: 5,
    margin: 5,
    borderRadius: 3,
    elevation: 3,
    // color: '#FFFFFF'
  };
  return (
    <View style={styles.container}>
      <View style={styles.menuPage}>
        <Logo style={{ 
        resizeMode: "contain",
        width: "100%",
        marginBottom: "1.3em",
        height: 103,
      }}/>
        <Navi />
      </View>
      <View style={styles.menuPage}>
        <Link to="/Contact" style={{ textDecoration: "none" }}>
          <Pressable
            style={{
              textDecoration: "none",
              backgroundColor: "#09CCC7",
              height: "7vw",
              maxWidth: 310,
              minHeight: 160,
              width: "100%",
              minWidth: 310,
              paddingLeft: 15,
              paddingBottom: 15,
              borderRadius: 10,
              margin: "5px 5px 25px 0"
            }}
          >
            <CardView
              title={"Leave Feedback"}
              titleFontSize={12}
              style={{ padding: 0 }}
            >
              <Image source={contactCorner2} style={styles.coloredCorners} />
              <Text style={styles.cardViewTitle}>Leave Feedback</Text>
              {/* <Text style={styles.cardViewDescription}>
                Lorem Ipsum et dolore magna aliqua
              </Text> */}
            </CardView>
          </Pressable>
        </Link>
        <Link to="/Contact" style={{ textDecoration: "none" }}>
          <Pressable
            style={{
              textDecoration: "none",
              backgroundColor: "none",
              height: "7vw",
              maxWidth: 310,
              minHeight: 160,
              width: "100%",
              minWidth: 310,
              paddingLeft: 15,
              paddingBottom: 15,
              borderRadius: 10,
              // outlineColor: "#09CCC7",
              // outlineStyle: "solid",
              // outlineWidth: 1,
              borderColor: "#09CCC7",
                borderStyle: "solid",
                borderWidth: 1,
              margin: "15px 5px 5px 0",
              marginTop: "2em",
            }}
          >
            <CardView
              title={"Get In Touch"}
              titleFontSize={12}
              style={{ padding: 0 }}
            >
              <Image source={contactCorner1} style={styles.coloredCorners} />
              <Text style={styles.cardViewTitle}>Get In Touch</Text>
              {/* <Text style={styles.cardViewDescription}>
                Lorem Ipsum et dolore magna aliqua
              </Text> */}
            </CardView>
          </Pressable>
        </Link>
        {/* <Link to="/Contact" style={{ textDecoration: "none" }}>
        <CardViewWithImage
          onPress={() => {
            console.log("clicked");
          }}
          withBackground={true}
          backgroundColor={"#1A1C22"}
          iconHeight={30}
          iconColor={"#333"}
          title={"Leave Feedback"}
          content={"LoremIpsum"}
          contentFontSize={20}
          contentFontFamily={"Typekit"}
          titleFontSize={30}
          titleFontFamily={"Typekit"}
          style={miniCardStyle}
        />
        </Link>
        <Link to="/Contact" style={{ textDecoration: "none" }}>
        <CardViewWithImage
          onPress={() => {
            console.log("clicked");
          }}
          withBackground={false}
          iconHeight={30}
          iconColor={"#333"}
          title={"Get In Touch"}
          content={"LoremIpsum"}
          contentFontSize={20}
          contentFontFamily={"Typekit"}
          titleFontSize={30}
          titleFontFamily={"Typekit"}
          style={miniCardStyle}
        />
        </Link> */}
      </View>
    </View>
  );
};

export default Menu;

const styles = StyleSheet.create({
  menuPage: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    //minHeight: "100vh",
    minWidth: 360,
  },
  container: {
    flex: "unset",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 1200,
    width: "100%",
    gap: "2em 0",
  },
  cardViewTitle: {
    fontFamily: "Gilroy-Bold",
    fontSize: 22,
    letterSpacing: 1,
    color: "white",
    marginTop:"4.5em",
  },
  cardViewDescription: {
    fontFamily: "Typekit",
    letterSpacing: 0,
    fontSize: "0.85em",
    color: "white",
  },
  coloredCorners: {
    width: 85,
    height: 85,
    alignSelf: "flex-end",
    position: "absolute",
    right: 0,
  },
});
