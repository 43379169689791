import { StatusBar } from "expo-status-bar";
import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  Button,
  TextInput,
  ScrollView,
  FlatList,
  Image,
} from "react-native";
// import { NativeRouter, Route, Link } from "react-router-native";
// import CompanyItem from "./components/CompanyItem";
// import CompanyInput from "./components/CompanyInput";
// import Logo from "./components/Logo";
// import AboutCompany from "./components/AboutCompany";
// import Footer from "./components/Footer";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
// import Home from "./components/Home";

export default function Navi(props) {
  return (
    <View>
      <Link to="/about" style={{textDecoration:"none"}} >
        <Text style={styles.navItem} onPress={() => {console.log("clicked")}}>About</Text>
      </Link>
      <Link to="/ForAdvisors" style={{textDecoration:"none"}} >
        <Text style={styles.navItem} onPress={() => {console.log("clicked")}}>For Advisors</Text>
      </Link>
          {/*
      <Link to="/ForAdvisors" target="_blank" rel="noopener noreferrer" style={{textDecoration:"none"}}>
        <Text style={styles.navItem}>For Advisors</Text>
 </Link>
       <Link to="/" style={{textDecoration:"none"}}>
        <Text style={styles.navItem}>Mutual Funds</Text>
      </Link> */}
      <Link to="/Home" style={{textDecoration:"none"}} >
        <Text style={styles.navItem} onPress={() => {console.log("clicked")}}>Search Companies</Text>
      </Link>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 20,
    flex: 1,
    backgroundColor: "#1A1C22",
    alignItems: "center",
    justifyContent: "center",
    // minHeight:'100vh',
  },
  header: {
    fontSize: 20,
  },
  navItem: {
    fontSize: "clamp(36px, 3.2vw, 65px)",
    fontFamily: "Gilroy-Bold",
    letterSpacing: 1,
    color:"white",
    lineHeight: "1.3em"
  },
});
