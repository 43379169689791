import React, { useContext, useState, useEffect } from "react";
import Helmet from 'react-helmet';
import { View, Text, Modal, StyleSheet, Pressable, Image } from "react-native";
import { BrowserRouter as Router, Link } from "react-router-dom";
import AppContext from "./AppContext";
import axios from "axios";
import Logo from "./Logo";
import RJLogo from "../assets/racial-justice-09.png";
import Footer from "./Footer";
import BackHomeButton from "./BackHomeButton";
import RJInfo from "../assets/racial-justice-infographic.png";
import Infographic from "./Infographics";
import Sharingoption from './SharignOptions'
// import Home from './Home'

const RacialJustice = () => {
  const context = useContext(AppContext);
    const [seoData, setSeo] = useState([]);
    
    const getSeoData = async () => {
      try {
        const seo = await axios.get(
          `${ process.env.APP_MANIFEST.Local_URL}seo?pageName=Racial`
        );
        setSeo(
          seo?.data[0]
        );
      } catch (error) {
        // handle error
        alert(error);
      }
    }
    
    useEffect(() => {
      if (seoData.length === 0) {
          console.log("Getting SEO Data");
        getSeoData();
      }
    });
    
    return (
      <>
      <Helmet>
        <meta charset="utf-8" />
        <meta httpequiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1.00001, viewport-fit=cover" />
            <meta name="description" content={seoData.metaDesc} />
            <meta name="keywords" content={seoData.metaKeywords} />
        <title>{seoData.pageTitle}</title>
      </Helmet><View style={styles.container}>
      <Logo
        style={{
          resizeMode: "contain",
          width: 250,
          height: 65,
          placeSelf: "center",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "2em",
        }}
      />
      <View style={styles.innerContainer}>
        <BackHomeButton />
        <View style={styles.innerPageContainer}>
          <Sharingoption />
          <Image
            source={RJLogo}
            style={{
              width: "clamp(320px, 400px, 24vw)",
              height: "clamp(320px, 400px, 23vw)",
            }}
          />
          <View style={styles.paragraph}>
            <Text
              style={{
                textAlign: "left",
                color: "#FFFFFF",
                fontSize: "clamp(45px, 3.2vw, 62px)",
                fontFamily: "Gilroy-Bold",
              }}
            >
              Racial Justice
            </Text>
            <Text
              style={{
                textAlign: "left",
                color: "#FFFFFF",
                fontSize: 24,
                lineHeight: "1.7em",
                marginTop: "1.7em",
                fontFamily: "Typekit",
                paddingBottom: "1.7em",
                borderBottom: "1px solid white",
              }}
            >
              {`“Racial injustice is the act of being unjust to an individual or group based on race, particularly concerning legally recognized rights. It includes discrimination based on race or ethnicity in voting, employment, housing, and the administration of justice… it refers to conduct such as racial profiling and the imposition of harsher penalties on convicted criminals, including the death penalty, based on race.”
`}
              <Link
                to={{
                  pathname:
                    "https://www.mylawquestions.com/what-is-racial-injustice.htm",
                }}
                target="_blank"
                style={{ textDecoration: "none", flex: "31%" }}
              >
                <Text style={styles.linkStyle}>
                  www.mylawquestions.com/what-is-racial-injustice
                </Text>
              </Link>
            </Text>
            <Infographic
              style={{
                resizeMode: "contain",
                maxWidth: 800,
                width: "100%",
                height: 600,
                placeSelf: "center",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "2em",
              }}
              source={RJInfo}
            />
            <Text
              style={{
                textAlign: "left",
                color: "#FFFFFF",
                fontSize: 24,
                lineHeight: "1.7em",
                marginTop: "1.7em",
                fontFamily: "Typekit",
              }}
            >
              {`The median wealth of white households in the US was $171,000.  That’s 10x the wealth of black households and 8x that of Hispanic households.

The average concentrations of air pollution exposure for minority communities compared with white residents: Latino 75% higher; Asian 73% higher, and African American are 61% higher.

In the US, black individuals are twice as likely to be unemployed than white individuals. Once employed, blacks earn nearly 25% less than their white counterparts.
`}
            </Text>
            <Text
              style={{
                textAlign: "left",
                color: "#FFFFFF",
                fontSize: 24,
                lineHeight: "1.7em",
                marginTop: "1.7em",
                fontFamily: "Typekit",
              }}
            >
              {`Under the US constitution, all are created equal and have equal rights to include “…Life, Liberty and the pursuit of Happiness.”  While progress has been made, there is still more we can all do to relinquish the inequities that continue today.  Racial injustice impacts all of us. 
“Injustice anywhere is a threat to justice everywhere.” – Martin Luther King, Jr.

`}
            </Text>
          </View>
        </View>
      </View>
      <Footer />
    </View></>
  );
};
const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  container: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    // backgroundImage: `url(${InteriorBG})`
  },
  innerContainer: {
    maxWidth: 1200,
    width: "100%",
    margin: "0 auto",
  },
  innerPageContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row-reverse",
    // justifyContent: "space-around",
    justifyContent:'space-between',
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  paragraph: {
    width: "clamp(65%, 100%, 780px)",
  },
  linkStyle: {
    textAlign: "left",
    color: "#FFFFFF",
    fontSize: 24,
    lineHeight: "1.7em",
    marginTop: "1.7em",
    fontFamily: "Typekit",
    fontWeight: "bold",
  },
});
export default RacialJustice;
