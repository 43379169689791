import React, { useContext, useState, useEffect } from "react";
import Helmet from 'react-helmet';
import axios from "axios";
import { View, Text, Modal, StyleSheet, Pressable, Image } from "react-native";
import { BrowserRouter as Router, Link } from "react-router-dom";
import AppContext from "./AppContext";
import Logo from "./Logo";
import POPLogo from "../assets/people-over-profits-10.19.png";
import Footer from "./Footer";
import BackHomeButton from "./BackHomeButton";
// import Home from './Home'

const AboutUs = () => {
  const context = useContext(AppContext);
    const [seoData, setSeo] = useState([]);
    
    const getSeoData = async () => {
      try {
        const seo = await axios.get(
          `${ process.env.APP_MANIFEST.Local_URL}seo?pageName=AboutUs`
        );
        setSeo(
          seo?.data[0]
        );
      } catch (error) {
        // handle error
        alert(error);
      }
    }
    
    useEffect(() => {
      if (seoData.length === 0) {
          console.log("Getting SEO Data");
        getSeoData();
      }
    });
  return (
    <><Helmet>
      <meta charset="utf-8" />
      <meta httpequiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1.00001, viewport-fit=cover" />
          <meta name="description" content={seoData.metaDesc} />
          <meta name="keywords" content={seoData.metaKeywords} />
      <title>{seoData.pageTitle}</title>
    </Helmet><View style={styles.container}>
        <Logo
          style={{
            resizeMode: "contain",
            width: 250,
            height: 65,
            placeSelf: "center",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "2em",
          }} />
        <View style={styles.innerContainer}>
          <BackHomeButton />
          <View style={styles.innerPageContainer}>
            <View style={styles.paragraph}>
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: "4vmax",
                  fontFamily: "Gilroy-Bold",
                }}
              >
                About Us
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: 17,
                  lineHeight: "1.7em",
                  marginTop: "1.7em",
                  fontFamily: "Typekit",
                  paddingBottom: "1.7em",
                  borderBottom: "1px solid white",
                }}
              >
                {`Our Mission
To be the go-to site for those who want to “vote with their dollars” both as consumers and as investors. We look behind the curtain of ESG and provide transparent data on the corporations that make up the S&P 500. 
`}
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: 17,
                  lineHeight: "1.7em",
                  marginTop: "1.7em",
                  fontFamily: "Typekit",
                }}
              >
                {`Our History
LightMoney.com is the brainchild of Dianna Burkholder, a Chartered Socially Responsible Investment Counselor (CSRIC) who is an advocate for social justice and the environment. Dianna has a strong passion for scuba diving, and this passion has allowed her to see firsthand, both the beauty of oceans and beaches across the globe, as well as see the damage being done by climate change. Her 15 years of experience in the financial industry combined with her love of scuba diving, has strongly and directly impacted her approach to investing.  Dianna has seen the increased appeal of ESG with investors and has also seen the shortfalls of the investment industry’s approach to it.  She has also seen how companies attempt to uphold a certain persona, while “greenwashing”, lacking transparency, and maintaining conflicts of interest, making it difficult for investors and advisors to really know a company’s true values and commitment to them.  Many times, ESG, as measured today, isn’t always effective in telling the full story of a company’s value system.  With that in mind, LightMoney.com was created, as an easy to use, straightforward website designed to shine light on the values of S&P 500 companies.`}
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: 17,
                  lineHeight: "1.7em",
                  marginTop: "1.7em",
                  fontFamily: "Typekit",
                }}
              >
                {`Our Methodology: 
We use publicly sourced data from values-aligned NGOs and Academia programs. We use data that is fully accessible to the public. Our methodology is shown on each company scorecard, and we welcome feedback on what ways to improve our data.  Because of our commitment to using fully transparent, public data, there are some limitations to what we can cover. As an example, Dianna is personally extremely passionate about ocean plastics, but right now, there is not a public dataset showing virgin plastic creation/use or of recycled resources for each corporation in the S&P 500. 
`}
              </Text>
            </View>
          </View>
        </View>
        <Footer />
      </View></>
  );
};
const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  container: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    // backgroundImage: `url(${InteriorBG})`
  },
  innerContainer: {
    maxWidth: 1200,
    width: "100%",
    margin: "0 auto",
  },
  innerPageContainer: {
    display: "flex",
    width: "clamp(340px, 100%, 1200px)",
    marginLeft: "auto",
    marginRight: "auto",
    flexDirection: "row-reverse",
    justifyContent: "space-around",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  paragraph: {
    width:"100%",
  },
});
export default AboutUs;
