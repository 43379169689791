// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { logEvent, getAnalytics } from "firebase/analytics";

// import firebase from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
console.log("process" , process.env.API_KEY)
const firebaseConfig = {
  // apiKey: process.env.API_KEY,
  // authDomain: process.env.AUTH_DOMAIN,
  // projectId: process.env.PROJECT_ID,
  // storageBucket: process.env.STORAGE_BUCKET,
  // messagingSenderId: process.env.MESSAGING_SENDER_ID,
  // appId: process.env.APP_ID,
  // measurementId: process.env.MEASUREMENT_ID

  apiKey: "AIzaSyCZ5XIXZapw4VFhVeIbjh309gft-QtrttE",
  authDomain: "lightmoney-1ad57.firebaseapp.com",
  projectId: "lightmoney-1ad57",
  storageBucket: "lightmoney-1ad57.appspot.com",
  messagingSenderId: "1066174668009",
  appId: "1:1066174668009:web:55ab0602ba93b48d772478",
  measurementId: "G-M1SJQQN18M"
};

// Initialize Firebase
//  export   const analytics=  firebase.initializeApp(config);
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
// export const screen_name= logEvent(analytics, 'screen_view', {
//     firebase_screen: "/darkmoney", 
//   })

export const buttonPress = () => logEvent(analytics, 'onPressAddToBasket');









